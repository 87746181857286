import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import { IErroRest, IRest, IRestGetIBanner, IRestPaginate } from "./Interfaces";

export const getEventAll = async (offset: number, limit: number) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/pt_BR/events?offset=${offset}&limit=${limit}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRestPaginate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const postEventNew = async (data: {
  name: string;
  description: string;
  eventStartDate: string;
  eventEndDate: string;
  accessKey: string;
  showHome: string;
  estabilishment: string;
}) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(`/admin/event`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        success: "Evento cadastrado com sucesso!",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getEventID = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/event/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRestGetIBanner) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putEvent = async (
  id: string,
  data: {
    name: string;
    description: string;
    eventStartDate: string;
    eventEndDate: string;
    accessKey?: string;
    showHome: string;
  }
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(`/admin/event/${id}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const deleteEvent = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .delete(`/admin/event/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const uploadImageEvent = async (id: string, formData: FormData) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(`/admin/upload/Event/${id}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const uploadImageEventImage = async (id: string, formData: FormData) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(`/admin/upload/EventImage/${id}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        success: "Evento enviado com sucesso!",
        pending: "Enviando imagem",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const uploadAdminEventImage = async (eventId: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(
            `/admin/event_image`,
            {
              event: eventId,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const deleteAdminEventImage = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .delete(`/admin/event_image/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Excluindo imagem...",
        success: "Imagem excluída com sucesso!",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

import {
  AddCircle,
  CheckCircleOutline,
  ClearOutlined,
  RefreshRounded,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import { format, toDate, compareDesc } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { digitalApiUrl } from "../../../Services/Api";
import { getEstablishment } from "../../../Services/Establishment";
import {
  deleteAdminEventImage,
  getEventID,
  postEventNew,
  putEvent,
  uploadAdminEventImage,
  uploadImageEvent,
  uploadImageEventImage,
} from "../../../Services/Event";
import {
  EventImage,
  IEstablishment,
  IGetEstablishments,
  IPostEvent,
  IRestGetIEvent,
} from "../../../Services/Interfaces";
import { TextH2 } from "../../../styles/global.styles";
import { ImgSgv } from "../../Login/stylesLogin";

enum TypeSearch {
  NAME = "name",
  ADDRESS = "address",
}

export const EventDetails = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [eventStartDate, setEventStartDate] = useState<string>("");
  const [eventEndDate, setEventEndDate] = useState<string>("");
  const [accessKey, setAccessKey] = useState<string>("");
  const [showHome, setShowHome] = useState<boolean>(true);
  const [estabilishmentSearch, setEstabilishmentSearch] = useState("");
  const [estabilishmentResult, setEstabilishmentResult] = useState<
    IEstablishment[]
  >([]);
  const [estabilishmentTypeSearch, setEstabilishmentTypeSearch] =
    useState<TypeSearch>(TypeSearch.NAME);
  const [estabilishment, setEstabilishment] = useState<
    IEstablishment | undefined
  >();
  const [filename, setFilename] = useState<string | undefined>(undefined);

  const [coverFile, setCoverFile] = useState<File | undefined>(undefined);
  const [imageFiles, setImageFiles] = useState<File[] | undefined>(undefined);

  const [eventImages, setEventImages] = useState<EventImage[] | undefined>(
    undefined
  );

  const isEditing = eventId !== "New";

  // -------VALIDATIONS--------------------------------
  // --------------------------------------------------
  const isNameValid = name.length > 0;
  const isDescriptionValid = description.length > 0;

  const pattern =
    /^\d{4}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([0-9][0-9])[:]([0-9][0-9])$/;
  const isEventStartDateValid =
    pattern.test(eventStartDate) &&
    eventStartDate.split("-")[0] >= new Date().getFullYear().toString();
  const isEventEndDateValid =
    pattern.test(eventEndDate) &&
    eventEndDate.split("-")[0] >= new Date().getFullYear().toString();
  const isAccessKeyValid = accessKey.length > 0 || isEditing;
  const isEstabilishmentValid = estabilishment && estabilishment !== undefined;
  const isImageFileValid =
    (coverFile !== undefined &&
      coverFile.name !== undefined &&
      coverFile.size !== undefined) ||
    isEditing;

  const isFormValid =
    isNameValid &&
    isDescriptionValid &&
    isEventStartDateValid &&
    isEventEndDateValid &&
    isAccessKeyValid &&
    isEstabilishmentValid &&
    isImageFileValid;
  // --------------------------------------------------
  // --------------------------------------------------

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const criarEvento = () => {
    if (!coverFile) {
      alert("Selecione uma imagem de capa");
      return;
    }

    if (!areValidDates({ eventStartDate, eventEndDate })) {
      toast.warning(
        "Data final do evento não pode ser menor que data inicial do evento!"
      );
      return;
    }

    const eventData = {
      name,
      description,
      eventStartDate: format(toDate(eventStartDate), "yyyy-MM-dd HH:mm:00"),
      eventEndDate: format(toDate(eventEndDate), "yyyy-MM-dd HH:mm:00"),
      accessKey,
      showHome: showHome ? "active" : "inactive",
      estabilishment: estabilishment?.id,
    } as IPostEvent;

    postEventNew(eventData)
      .then((res) => {
        const evtId = (res as IRestGetIEvent).data.data.id;

        if (coverFile && isImageFileValid) {
          const formData = new FormData();
          formData.append("upload[uploadFile][file]", coverFile);
          uploadImageEvent(evtId, formData);
        }

        if (imageFiles && imageFiles.length > 0) {
          imageFiles.forEach((file) => {
            uploadAdminEventImage(evtId).then((res) => {
              const eventImageId = (res as IRestGetIEvent).data.data.id;
              const formDataImage = new FormData();
              formDataImage.append("upload[uploadFile][file]", file);
              uploadImageEventImage(eventImageId, formDataImage);
            });
          });
        }

        toast.info("Evento cadastrado com sucesso!");
      })
      .finally(() => Rota(`/eventos`));
  };

  const EditarEvento = () => {
    if (!eventId) {
      return;
    }

    if (!areValidDates({ eventStartDate, eventEndDate })) {
      toast.warning(
        "Data final do evento não pode ser menor que data inicial do evento!"
      );
      return;
    }

    const eventData = {
      name,
      description,
      eventStartDate: format(toDate(eventStartDate), "yyyy-MM-dd HH:mm:00"),
      eventEndDate: format(toDate(eventEndDate), "yyyy-MM-dd HH:mm:00"),
      accessKey,
      showHome: showHome ? "active" : "inactive",
      estabilishment: estabilishment?.id,
    } as IPostEvent;

    putEvent(eventId, eventData).then(() => {
      toast.info("Evento editado com sucesso!");
      Rota(`/eventos`);
    });
  };

  useEffect(() => {
    if (isEditing && eventId) {
      getEventID(eventId).then((response) => {
        const { data } = response as IRestGetIEvent;

        setName(data.data.name);
        setDescription(data.data.description);
        setEventStartDate(
          format(toDate(data.data.eventStartDate), "yyyy-MM-dd'T'HH:mm")
        );
        setEventEndDate(
          format(toDate(data.data.eventEndDate), "yyyy-MM-dd'T'HH:mm")
        );
        setShowHome(data.data.showHome);
        setAccessKey(data.data.accessKey);
        setEventImages(data.data.eventImages);
        setEstabilishment({
          id: data.data.estabilishment.id,
          name: data.data.estabilishment.name,
          addressInGoogleFormat: data.data.estabilishment.addressInGoogleFormat,
        });
        setFilename(
          data.data.filename ? digitalApiUrl + data.data.filename : undefined
        );
      });
    }
  }, [eventId, isEditing]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!isEditing) {
        setCoverFile(file);
      } else if (eventId) {
        const formData = new FormData();
        formData.append("upload[uploadFile][file]", file);
        uploadImageEvent(eventId, formData).then((result) => {
          getEventID(eventId).then((response) => {
            const { data } = response as IRestGetIEvent;

            setFilename(
              data.data.filename
                ? digitalApiUrl + data.data.filename
                : undefined
            );
          });
        });
      }
    }
  };

  const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!isEditing) {
        setImageFiles(imageFiles ? [...imageFiles, file] : [file]);
      } else if (eventId) {
        uploadAdminEventImage(eventId).then((res) => {
          const eventImageId = (res as IRestGetIEvent).data.data.id;
          const formDataImage = new FormData();
          formDataImage.append("upload[uploadFile][file]", file);
          uploadImageEventImage(eventImageId, formDataImage).then((result) => {
            getEventID(eventId).then((response) => {
              const { data } = response as IRestGetIEvent;

              setEventImages(
                data.data.eventImages ? data.data.eventImages : undefined
              );
            });
            // eventImages && eventImages?.length > 0
            //   ? setEventImages([...eventImages, file])
            //   : setEventImages([file]);
          });
        });
      }
    }
  };

  const handleDeleteEventImage = ({ id }: EventImage) => {
    const message =
      "Você tem certeza que deseja deletar essa imagem?\nEsta ação não pode ser desfeita.";
    if (window.confirm(message) === true) {
      deleteAdminEventImage(id).then(() => {
        eventImages &&
          setEventImages([
            ...eventImages?.filter((eventImage) => eventImage.id !== id),
          ]);
      });
    }
  };

  const handleDeleteImageFile = (index: number) => {
    const message =
      "Você tem certeza que deseja deletar essa imagem?\nEsta ação não pode ser desfeita.";
    if (window.confirm(message) === true) {
      imageFiles &&
        setImageFiles([...imageFiles?.filter((image, i) => i !== index)]);
    }
  };

  const handleSearch = () => {
    if (estabilishmentSearch.length < 4) {
      alert("Favor informar uma valor válido para pesquisa.");
      return;
    }

    getEstablishment(
      0,
      9999,
      estabilishmentTypeSearch === TypeSearch.NAME ? estabilishmentSearch : "",
      estabilishmentTypeSearch === TypeSearch.ADDRESS
        ? estabilishmentSearch
        : "",
      false
    )
      .then((result) =>
        setEstabilishmentResult((result as IGetEstablishments).data.data)
      )
      .finally(() => {
        setEstabilishment(undefined);
        setEstabilishmentSearch("");
      });
  };

  const areValidDates = ({
    eventStartDate,
    eventEndDate,
  }: {
    eventStartDate: string;
    eventEndDate: string;
  }) => (compareDesc(eventStartDate, eventEndDate) === 1 ? true : false);

  return (
    <Stack spacing={2}>
      <TextH2>{isEditing ? "Editar Evento" : "Novo Evento"}</TextH2>
      <Grid container spacing={2} width={"1000px"}>
        <Grid xs={12} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
            }}
            autoComplete="off"
            id="name"
            label="Nome"
            variant="outlined"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid xs={12} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
            }}
            autoComplete="off"
            id="description"
            multiline
            rows={4}
            label="Descrição"
            variant="outlined"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>

        <Grid xs={4} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
            }}
            autoComplete="off"
            id="eventStartDate"
            label="Data inicial"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            value={eventStartDate}
            onChange={(e) => setEventStartDate(e.target.value)}
          />
        </Grid>

        <Grid xs={4} item>
          <TextField
            style={{
              width: "100%",
            }}
            autoComplete="off"
            id="eventEndDate"
            label="Data final"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            value={eventEndDate}
            onChange={(e) => setEventEndDate(e.target.value)}
          />
        </Grid>

        <Grid xs={4} item>
          <TextField
            style={{
              width: "100%",
            }}
            autoComplete="off"
            id="accessKey"
            label="Código de acesso"
            variant="outlined"
            type="text"
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
          />
        </Grid>

        <Grid xs={3} item paddingLeft={"0px !important"}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tipo da busca</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={estabilishmentTypeSearch}
              label="Tipo da busca"
              defaultChecked
              onChange={(e) =>
                setEstabilishmentTypeSearch(e.target.value as TypeSearch)
              }
            >
              <MenuItem value={TypeSearch.NAME}>Nome</MenuItem>
              <MenuItem value={TypeSearch.ADDRESS}>Endereço</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={7} item>
          <TextField
            style={{
              width: "100%",
            }}
            autoComplete="off"
            id="estabilishment"
            label="Estabelecimento"
            variant="outlined"
            type="text"
            value={estabilishmentSearch}
            onChange={(e) => setEstabilishmentSearch(e.target.value)}
          />
        </Grid>

        <Grid xs={2} item>
          <Button
            component="label"
            variant="contained"
            fullWidth
            style={{ height: "100%" }}
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </Grid>

        {estabilishmentResult && estabilishmentResult.length > 0 && (
          <Grid
            xs={12}
            item
            padding={20}
            borderRadius={1}
            maxHeight={200}
            // height={'100%'}
            gap={10}
            overflow={"hidden"}
            style={{ overflowY: "scroll" }}
            marginY={2}
            border={"1px solid #c0c0c0"}
          >
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              gap={2}
              style={{ scrollBehavior: "smooth" }}
            >
              {estabilishmentResult.map((result) => (
                <Box
                  bgcolor={
                    estabilishment && estabilishment.id === result.id
                      ? "#1976d2"
                      : "#15c6c6"
                  }
                  paddingLeft={2}
                  color={"white"}
                  width={"fit-content"}
                  borderRadius={10}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {result.name}
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      estabilishment && estabilishment.id === result.id
                        ? setEstabilishment(undefined)
                        : setEstabilishment(result);
                    }}
                  >
                    {estabilishment && estabilishment.id === result.id ? (
                      <ClearOutlined />
                    ) : (
                      <CheckCircleOutline />
                    )}
                  </IconButton>
                </Box>
              ))}
            </Stack>
          </Grid>
        )}

        {estabilishment &&
          estabilishmentResult &&
          estabilishmentResult.length < 1 && (
            <Grid
              xs={12}
              item
              padding={20}
              borderRadius={1}
              maxHeight={200}
              // height={'100%'}
              gap={10}
              overflow={"hidden"}
              style={{ overflowY: "scroll" }}
              marginY={2}
              border={"1px solid #c0c0c0"}
            >
              <Stack
                direction={"row"}
                flexWrap={"wrap"}
                gap={2}
                style={{ scrollBehavior: "smooth" }}
              >
                <Box
                  bgcolor={"#1976d2"}
                  paddingLeft={2}
                  color={"white"}
                  width={"fit-content"}
                  borderRadius={10}
                  display={"flex"}
                  alignItems={"center"}
                >
                  {estabilishment.name}
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      setEstabilishment(undefined);
                    }}
                  >
                    <ClearOutlined />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>
          )}

        <TextH2 style={{ marginTop: 20, color: "#aaaaaa" }}>
          Capa do Evento 420x150 (JPG, PNG)
        </TextH2>

        <Grid
          xs={12}
          item
          display={"flex"}
          style={{ padding: 0, marginTop: 20 }}
        >
          <VisuallyHiddenInput
            id="coverImage"
            type="file"
            onChange={handleFileChange}
          />
          {!coverFile && !filename && (
            <label
              htmlFor="coverImage"
              style={{
                width: 420,
                height: 150,
                backgroundColor: "#FFFFFF",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <AddCircle color="primary" />
            </label>
          )}
          {(coverFile || filename) && (
            <div
              style={{
                width: "420px",
                height: "150px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <label
                htmlFor={"coverImage"}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 5,
                  right: 5,
                  borderRadius: "50%",
                  backgroundColor: "#FFFFFF",
                  width: 22,
                  height: 22,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RefreshRounded color="primary" />
              </label>
              <img
                width={420}
                height={150}
                src={
                  coverFile
                    ? URL.createObjectURL(coverFile)
                    : filename && digitalApiUrl + filename
                }
                alt="Evento"
                loading="lazy"
              />
            </div>
          )}
        </Grid>

        <TextH2 style={{ marginTop: 20, color: "#aaaaaa" }}>
          Imagens adicionais 150x150 (JPG, PNG)
        </TextH2>

        <Grid xs={12} item display={"flex"} style={{ paddingLeft: 0 }} gap={3}>
          <Grid item display={"flex"}>
            <label
              htmlFor="imageEvent"
              style={{
                width: 100,
                height: 100,
                backgroundColor: "#FFFFFF",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <AddCircle color="primary" />
            </label>
            <VisuallyHiddenInput
              id="imageEvent"
              type="file"
              onChange={handleImageFileChange}
            />
          </Grid>

          {eventImages &&
            eventImages.length > 0 &&
            eventImages.map((image, i) => (
              <Grid key={i} item display={"flex"} justifyContent={"center"}>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                    padding: 5,
                  }}
                >
                  <div
                    onClick={() => handleDeleteEventImage(image)}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 5,
                      right: 5,
                      borderRadius: "50%",
                      backgroundColor: "#FFFFFF",
                      width: 22,
                      height: 22,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveCircle color="error" />
                  </div>
                  <img
                    srcSet={digitalApiUrl + image.filename}
                    src={digitalApiUrl + image.filename}
                    alt={image.id}
                    width={"100%"}
                    loading="lazy"
                  />
                </div>
              </Grid>
            ))}

          {imageFiles &&
            imageFiles.length > 0 &&
            imageFiles.map((image, i) => (
              <Grid key={i} item display={"flex"} justifyContent={"center"}>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                    padding: 5,
                  }}
                >
                  <div
                    onClick={() => handleDeleteImageFile(i)}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 5,
                      right: 5,
                      borderRadius: "50%",
                      backgroundColor: "#FFFFFF",
                      width: 22,
                      height: 22,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RemoveCircle color="error" />
                  </div>
                  <img
                    srcSet={URL.createObjectURL(image)}
                    src={URL.createObjectURL(image)}
                    alt={image.name}
                    width={"100%"}
                    loading="lazy"
                  />
                </div>
              </Grid>
            ))}
        </Grid>

        <Grid xs={12} item>
          <FormControlLabel
            control={
              <Switch
                checked={showHome}
                onChange={(e) => {
                  setShowHome(e.target.checked);
                }}
              />
            }
            label="Mostrar Evento na Home"
            style={{ marginBottom: 10 }}
          />
        </Grid>

        <Grid xs={12} item>
          <button
            onClick={() => (isEditing ? EditarEvento() : criarEvento())}
            disabled={!isFormValid}
            style={{
              backgroundColor: !isFormValid ? "#86cb1757" : "#1dcb17",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "56px",
              width: "100px",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ImgSgv
              style={{ marginRight: "5px" }}
              width={18}
              src="/icons/menu/new.svg"
            />
            {isEditing ? "Editar" : "Criar"}
          </button>
        </Grid>
      </Grid>
    </Stack>
  );
};

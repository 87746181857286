import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import {
  IErroRest,
  IRestGraphicCustomerCheckIn,
  IRestGraphicEstablishment,
  IRestGraphicEstablishmentCheckIn,
  IRestGraphicReportCustomerNetworking,
  IRestGraphicReportUser,
} from "./Interfaces";

export const getReportCustomerCheckIn = async (
  startDate: string,
  finishDate: string,
  xlsx: boolean = false
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `admin/report/customer_checkin?startDate=${startDate}&finishDate=${finishDate}${
              xlsx ? `&export=1` : ``
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: xlsx ? "blob" : "json",
            }
          )
          .then((response: IRestGraphicCustomerCheckIn) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getReportEstablishment = async (
  startDate: string,
  finishDate: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `admin/report/establishment?startDate=${startDate}&finishDate=${finishDate}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestGraphicEstablishment) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getReportEstablishmentCheckIn = async (
  startDate: string,
  finishDate: string,
  xlsx: boolean = false
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `admin/report/establishment_checkin?startDate=${startDate}&finishDate=${finishDate}${
              xlsx ? `&export=1` : ``
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: xlsx ? "blob" : "json",
            }
          )
          .then((response: IRestGraphicEstablishmentCheckIn) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getReportUser = async (startDate: string, finishDate: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/report/user?startDate=${startDate}&finishDate=${finishDate}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestGraphicReportUser) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getReportCustomerCheckin = async (
  startDate: string,
  finishDate: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/report/customer_checkin?startDate=${startDate}&finishDate=${finishDate}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestGraphicReportUser) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getReportCustomerNetworking = async (
  startDate: string,
  finishDate: string,
  xlsx: boolean = false
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/report/customer_networking?startDate=${startDate}&finishDate=${finishDate}${
              xlsx ? `&export=1` : ``
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: xlsx ? "blob" : "json",
            }
          )
          .then((response: IRestGraphicReportCustomerNetworking) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

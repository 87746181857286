import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import { IErroRest, IGetEstablishmentID, IRestPaginate } from "./Interfaces";

export const getEstablishment = async (
  offset: number,
  limit: number,
  establishmentName: string = "",
  addressInGoogleFormat: string = "",
  xlsx: boolean = false
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/establishment?offset=${offset}&limit=${limit}${
              xlsx ? "&export=1" : ""
            }${
              establishmentName
                ? "&criteria[establishment.name]=" + establishmentName
                : ""
            }${
              addressInGoogleFormat
                ? "&criteria[establishment.addressInGoogleFormat]=" +
                  addressInGoogleFormat
                : ""
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: xlsx ? "blob" : "json",
            }
          )
          .then((response: IRestPaginate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getEstablishmentID = async (ID: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/establishment/${ID}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IGetEstablishmentID) => {            
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

import styled from "@emotion/styled";

export const SectionBox = styled("div")`
  display: flex;
  flex-direction: column;
  width: 590px;
  min-height: 240px;
  background-color: #ffffff;
  border: 10px solid #ffffff;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.1));
  margin: 10px;
`;

export const SectionBoxTitle = styled("div")`
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  align-items: center;
`;

export const SectionBoxTitleIcon = styled("div")`
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  align-items: center;
`;

export const GradienteDowBox = styled("div")`
  background-image: linear-gradient(0deg, #f2f2f2 0%, #f2f2f2 100%);
  width: 100%;
  height: 1px;
`;

export const ComponentContentBox = styled("div")``;

import Chart from "react-google-charts";
import { TextH2 } from "../../../styles/global.styles";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/pt-br";

import {
  getReportCustomerCheckIn,
  getReportCustomerNetworking,
  getReportEstablishment,
  getReportEstablishmentCheckIn,
  getReportUser,
} from "../../../Services/Report";
import {
  IRestGraphicCustomerCheckIn,
  IRestGraphicEstablishment,
  IRestGraphicEstablishmentCheckIn,
  IRestGraphicReportCustomerNetworking,
  IRestGraphicReportUser,
} from "../../../Services/Interfaces";
import { ImgSgv } from "../../Login/stylesLogin";
import dayjs from "dayjs";
import { BoxReport } from "./Box";
import { BoxReportMax } from "./BoxMax";
import { BoxReportDuplo } from "./BoxDuplo";
import { AxiosResponse } from "axios";

export const PlatformReport = () => {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [filterStartEnd, setFilterStartEnd] = useState({
    start: "",
    end: "",
  });

  const [optionsCustomerCheckin] = useState({
    title: " ",
    // pieHole: 0.4, // Define o tamanho do buraco no gráfico de rosca (opcional)
    colors: ["#A5A8AA", "#383838", "#0F787F", "#17C4CB"], // Define a paleta de cores
  });

  const [dataCustomerCheckin, setCustomerCheckin] = useState<
    [string, number | string][]
  >([]);

  const [dataMeetsMensal, setMeetsMensal] = useState<
    [string, number | string][]
  >([]);
  const [dataGeneral, setGeneral] = useState<{
    accepted: number;
    not_accepted: number;
    canceled: number;
  }>({
    accepted: 0,
    not_accepted: 0,
    canceled: 0,
  });

  const ExportCSV = (report: string) => {
    switch (report) {
      case "customerNetworking":
        getReportCustomerNetworking(
          filterStartEnd.start,
          filterStartEnd.end,
          true
        ).then((response) => {
          const dow = response as AxiosResponse<Blob>;
          if (dow.status === 200) {
            const downloadUrl = window.URL.createObjectURL(dow.data);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `CustomerNetworking-${new Date().getTime()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        });
        break;

      case "customerCheckin":
        getReportCustomerCheckIn(
          filterStartEnd.start,
          filterStartEnd.end,
          true
        ).then((response) => {
          const dow = response as AxiosResponse<Blob>;
          if (dow.status === 200) {
            const downloadUrl = window.URL.createObjectURL(dow.data);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `CustomerCheckin-${new Date().getTime()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        });
        break;

      case "establishmentCheckin":
        getReportEstablishmentCheckIn(
          filterStartEnd.start,
          filterStartEnd.end,
          true
        ).then((response) => {
          const dow = response as AxiosResponse<Blob>;
          if (dow.status === 200) {
            const downloadUrl = window.URL.createObjectURL(dow.data);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `EstablishmentCheckin-${new Date().getTime()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getReportCustomerCheckIn(filterStartEnd.start, filterStartEnd.end).then(
      (response) => {
        const { data } = response as IRestGraphicCustomerCheckIn;

        const dataReportCustomerCheckIn = [["Nome", "Checkins"]] as [
          string,
          number | string
        ][];
        data.data.map((item) =>
          dataReportCustomerCheckIn.push([item.name, item.checkins])
        );
        setCustomerCheckin(dataReportCustomerCheckIn);
      }
    );

    getReportEstablishment(filterStartEnd.start, filterStartEnd.end).then(
      (response) => {
        const { data } = response as IRestGraphicEstablishment;
        setEstablishment({ total: data.data.total });
      }
    );

    getReportEstablishmentCheckIn(
      filterStartEnd.start,
      filterStartEnd.end
    ).then((response) => {
      const { data } = response as IRestGraphicEstablishmentCheckIn;
      const dataCheckIn = [["Nome", "Checkins"]] as [string, number | string][];
      data.data.map((item) => dataCheckIn.push([item.name, item.checkins]));
      setEstablishmentCheckIn(dataCheckIn);
    });

    getReportUser(filterStartEnd.start, filterStartEnd.end).then((response) => {
      const { data } = response as IRestGraphicReportUser;
      setReportUser({
        active: data.data.active,
        blocked: data.data.blocked,
        deleted: data.data.deleted,
      });
    });

    getReportCustomerNetworking(filterStartEnd.start, filterStartEnd.end).then(
      (response) => {
        const { data } = response as IRestGraphicReportCustomerNetworking;
        const dataResponse = [
          [
            { type: "date", label: "Mes" },
            "Meetings Aceitos",
            "Meetings Cancelados",
            "Meetings não aceitos",
          ],
        ] as any;
        data.data.monthly.map((item) => {
          return dataResponse.push([
            new Date(+item.year, +item.month - 1),
            +item.accepted,
            +item.canceled,
            +item.not_accepted,
          ]);
        });
        setMeetsMensal(dataResponse);
        setGeneral(data.data.general);
      }
    );
  }, [filterStartEnd.end, filterStartEnd.start]);

  const [dataEstablishment, setEstablishment] = useState<{ total: number }>({
    total: 0,
  });

  const [optionsEstabelecimentosCheckin] = useState({
    title: " ",
    colors: ["#A5A8AA", "#383838", "#0F787F", "#17C4CB"],
  });

  const [dataEstablishmentCheckIn, setEstablishmentCheckIn] = useState<
    [string, number | string][]
  >([]);

  const [dataReportUser, setReportUser] = useState<{
    blocked: number;
    active: number;
    deleted: number;
  }>({
    blocked: 0,
    active: 0,
    deleted: 0,
  });

  const handleFilter = () => {
    const startDate = dayjs(start, "DD/MM/YYYY").format("YYYY-MM-DD");
    const endDate = dayjs(end, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (new Date(startDate) < new Date(endDate)) {
      setFilterStartEnd({
        start: startDate,
        end: endDate,
      });
    } else {
      alert("Data inicial não pode ser maior que a data final");
    }
  };

  function UserStatusArea(active: number, blocked: number, deleted: number) {
    const tableStyle: React.CSSProperties = {
      display: "grid",
      gridTemplateRows: "auto auto", // Duas linhas
      gridTemplateColumns: "1fr 1fr 1fr", // tres colunas
      gap: "10px",
      paddingTop: "20px",
      color: "white",
    };

    const cellStyle: React.CSSProperties = {
      padding: "10px",
      textAlign: "center", // Defina como 'center'
      backgroundColor: "#0F787F",
    };

    const totalCellStyle: React.CSSProperties = {
      ...cellStyle,
      gridColumn: "span 3", // Ocupa duas colunas
    };

    return (
      <div style={tableStyle}>
        <div style={totalCellStyle}>
          <h4>Total de Usuários</h4>
          <h1>{active + blocked}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Bloqueados</h4>
          <h1>{blocked}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Ativos</h4>
          <h1>{active}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Deletados</h4>
          <h1>{deleted}</h1>
        </div>
      </div>
    );
  }

  function MeetsGeral(
    Meets: {
      accepted: number;
      not_accepted: number;
      canceled: number;
    } = dataGeneral
  ) {
    const tableStyle: React.CSSProperties = {
      display: "grid",
      gridTemplateRows: "auto auto", // Duas linhas
      gridTemplateColumns: "1fr 1fr", // Duas colunas
      gap: "10px",
      paddingTop: "20px",
      color: "white",
    };

    const cellStyle: React.CSSProperties = {
      padding: "10px",
      textAlign: "center", // Defina como 'center'
      backgroundColor: "#0F787F",
    };

    const totalCellStyle: React.CSSProperties = {
      ...cellStyle,
      gridColumn: "span 2", // Ocupa duas colunas
    };

    return (
      <div style={tableStyle}>
        <div style={totalCellStyle}>
          <h4>Meets Aceitos</h4>
          <h1>{Meets.accepted}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Meets Cancelados</h4>
          <h1>{Meets.canceled}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Meets não aceitos</h4>
          <h1>{Meets.not_accepted}</h1>
        </div>
      </div>
    );
  }

  function Establishment(total: number) {
    const tableStyle: React.CSSProperties = {
      display: "grid",
      gridTemplateRows: "auto auto", // Duas linhas
      gridTemplateColumns: "1fr 1fr", // Duas colunas
      gap: "10px",
      paddingTop: "20px",
      color: "white",
    };

    const cellStyle: React.CSSProperties = {
      padding: "10px",
      textAlign: "center", // Defina como 'center'
      backgroundColor: "#0F787F",
    };

    const totalCellStyle: React.CSSProperties = {
      ...cellStyle,
      gridColumn: "span 2", // Ocupa duas colunas
    };

    return (
      <div style={tableStyle}>
        <div style={totalCellStyle}>
          <h4>Total</h4>
          <h1>{total}</h1>
        </div>
      </div>
    );
  }

  const optionsMeetsMensal = {
    chart: {
      title: " ",
      colors: ["#A5A8AA", "#383838", "#0F787F", "#17C4CB"],
    },
    width: 900,
    height: 500,
    series: {
      0: { axis: "Quantidade", color: "#383838" },
      1: { axis: "Quantidade", color: "#17C4CB" },
    },
    axes: {
      y: {
        Quantidade: { label: "Quantidade" },
      },
    },
  };

  return (
    <div>
      <br />
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <ImgSgv
          style={{ marginRight: "10px" }}
          width={26}
          src="/icons/menu/plataforma.svg"
        />
        <TextH2>Relatórios</TextH2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              marginRight: "20px",
            }}
          >
            <DatePicker
              label="Data Inicial"
              onChange={(data: any) => {
                setStart(data.format("DD/MM/YYYY"));
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginRight: "20px",
            }}
          >
            <DatePicker
              label="Data Fim"
              onChange={(data: any) => {
                setEnd(data.format("DD/MM/YYYY"));
              }}
            />
          </div>
          <button
            onClick={handleFilter}
            disabled={false}
            style={{
              backgroundColor: false ? "#86cb1757" : "#000000",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "56px",
              width: "260px",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImgSgv
              style={{ marginRight: "5px" }}
              width={18}
              src="/icons/menu/filter.svg"
            />
            Filtrar Relatório
          </button>
        </div>
      </LocalizationProvider>
      <br />
      <br />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          alignContent: "space-between",
          width: "100%",
        }}
      >
        <BoxReport
          InternalComponent={UserStatusArea(
            dataReportUser.active,
            dataReportUser.blocked,
            dataReportUser.deleted
          )}
          title={"Usuários"}
          ImgSgvIco="/icons/menu/usuarios.svg"
        />
        <BoxReport
          InternalComponent={Establishment(dataEstablishment.total)}
          title={"Estabelecimentos"}
          ImgSgvIco="/icons/menu/estabelecimento.svg"
        />
        <BoxReport
          InternalComponent={MeetsGeral()}
          title={"Meetings"}
          ImgSgvIco="/icons/menu/meets.svg"
        />
      </div>
      <br />
      <br />
      {dataMeetsMensal.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            alignContent: "space-between",
            width: window.innerWidth < 1500 ? "900px" : "100%",
          }}
        >
          <BoxReportMax
            ExportCSV={ExportCSV}
            InternalComponent={
              <Chart
                chartType="Line"
                data={dataMeetsMensal}
                options={optionsMeetsMensal}
              />
            }
            title={"Meetings mensais"}
            ImgSgvIco="/icons/menu/estabelecimento.svg"
          />
        </div>
      )}
      <br />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: window.innerWidth < 1500 ? "936px" : window.innerWidth - 480,
        }}
      >
        {dataCustomerCheckin.length > 0 && (
          <BoxReportDuplo
            tipoExport="customerCheckin"
            ExportCSV={ExportCSV}
            InternalComponent={
              <Chart
                chartType="PieChart"
                data={dataCustomerCheckin}
                options={optionsCustomerCheckin}
              />
            }
            title={"Relatório de checkins dos usuários"}
            ImgSgvIco="/icons/menu/estabelecimento.svg"
          />
        )}
        {dataEstablishmentCheckIn.length > 0 && (
          <BoxReportDuplo
            tipoExport="establishmentCheckin"
            ExportCSV={ExportCSV}
            InternalComponent={
              <Chart
                chartType="PieChart"
                data={dataEstablishmentCheckIn}
                options={optionsEstabelecimentosCheckin}
              />
            }
            title={"Relatório de checkins nos estabelecimentos"}
            ImgSgvIco="/icons/menu/estabelecimento.svg"
          />
        )}
      </div>
    </div>
  );
};

import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import { IErroRest, IRest, IRestGetIBanner, IRestPaginate } from "./Interfaces";

export const getBannerAll = async (offset: number, limit: number) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/pt_BR/banners?offset=${offset}&limit=${limit}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRestPaginate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const postBannerNew = async (data: {
  name: string;
  title: string;
  displayOrder: string;
  line: string;
}) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(`/admin/banner`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        success: "Banner cadastrado com sucesso!",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getBannerID = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/admin/banner/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRestGetIBanner) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putBanner = async (
  id: string,
  data: {
    displayOrder?: string;
    isActive?: string;
    url?: string;
    internalUrl?: string;
  }
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(`/admin/banner/${id}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const deleteBanner = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .delete(`/admin/banner/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "json",
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const uploadImageBanner = async (id: string, formData: FormData) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(`/admin/upload/Banner/${id}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response: IRest) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

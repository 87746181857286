import { TextField } from "@mui/material";
import { TextH2 } from "../../../styles/global.styles";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  IGetEstablishmentID,
  IRestGetEstablishmentID,
} from "../../../Services/Interfaces";
import { getEstablishmentID } from "../../../Services/Establishment";

export const EstablishmentDetails = () => {
  const location = useLocation();
  const { establishmentID } = useParams();
  const [Establishment, setEstablishment] = useState<IRestGetEstablishmentID>();

  useEffect(() => {
    if (
      establishmentID &&
      establishmentID !== "New" &&
      location.pathname.split("/")[1] === "EstablishmentsRegistration"
    ) {
      getEstablishmentID(establishmentID).then((response) => {
        const { data } = response as IGetEstablishmentID;
        setEstablishment(data.data);
      });
    }
  }, [establishmentID, location.pathname]);

  return (
    <div>
      <TextH2>Estabelecimentos Detalhes</TextH2>
      <br />
      {Establishment && establishmentID !== "New" && (
        <>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="id"
              label="id"
              variant="outlined"
              type="text"
              value={Establishment?.id || ""}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="name"
              label="Nome"
              variant="outlined"
              type="text"
              value={Establishment?.name || ""}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="mapCoordinates"
              label="Coordenada Geográfica"
              variant="outlined"
              type="text"
              value={Establishment?.mapCoordinates || ""}
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="addressInGoogleFormat"
              label="Endereço formato Google"
              variant="outlined"
              type="text"
              value={Establishment?.addressInGoogleFormat || ""}
            />
          </div>
          <br />
        </>
      )}
      {establishmentID === "New" && (
        <TextH2>Não permitido criar estabelecimentos</TextH2>
      )}
      <br />
    </div>
  );
};

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { TextH2 } from "../../../styles/global.styles";
import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  getAppUserID,
  putAppUserBlock,
  putAppUserUnblock,
} from "../../../Services/AppUser";
import {
  IRestGetAppUserID,
  IRestUserAdminCreate,
  IUserData,
} from "../../../Services/Interfaces";
import {
  getAdminAppUserID,
  postAdminUserCreate,
  putAdminUserEdit,
  putAppUserPromote,
} from "../../../Services/AdminUser";
import { ImgSgv } from "../../Login/stylesLogin";
import { format } from "date-fns";
import Swal from "sweetalert2";

export const UserDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();
  const [userData, setUserData] = useState<IUserData>();

  const [email, setEmail] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const criarUserAdmin = () => {
    postAdminUserCreate(email, password, fullName).then((res) => {
      const { data } = res as IRestUserAdminCreate;
      Rota(`/UserAdminRegister/${data.data.id}`);
    });
  };

  const EditarUserAdmin = () => {
    if (userId !== "New" && userId !== undefined)
      putAdminUserEdit(userId, email, password, fullName).then(() => {
        // const { data } = res as IRestUserAdminCreate;
        window.location.reload();
      });
  };

  useEffect(() => {
    if (
      userId &&
      userId !== "New" &&
      location.pathname.split("/")[1] === "UserManagement"
    ) {
      getAppUserID(userId).then((response) => {
        const { data } = response as IRestGetAppUserID;
        setEmail(userData?.email || "");
        setFullName(userData?.profile?.fullName || "");
        setUserData(data.data);
      });
    }

    if (
      userId &&
      userId !== "New" &&
      location.pathname.split("/")[1] === "UserAdminRegister"
    ) {
      getAdminAppUserID(userId).then((response) => {
        const { data } = response as IRestGetAppUserID;
        setEmail(userData?.email || "");
        setFullName(userData?.profile?.fullName || "");
        setUserData(data.data);
      });
    }
  }, [location.pathname, userData?.email, userData?.profile?.fullName, userId]);

  return (
    <div>
      <TextH2>Usuário Detalhes</TextH2>
      <br />

      {userId === "New" && (
        <div>
          <TextH2>Novo Usuário Administrador</TextH2>
          <br />
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              autoComplete="off"
              id="name"
              label="E-mail"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              autoComplete="off"
              id="fulName"
              label="Nome Completo"
              variant="outlined"
              type="text"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              autoComplete="off"
              id="password"
              label="Senha"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              autoComplete="off"
              id="email"
              label="Repita a senha"
              variant="outlined"
              type="password"
              value={passwordRepeat}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
              }}
            />
            <button
              onClick={criarUserAdmin}
              disabled={
                email.length === 0 ||
                fullName.length === 0 ||
                password.length < 8 ||
                passwordRepeat.length < 8 ||
                password !== passwordRepeat
              }
              style={{
                backgroundColor:
                  email.length === 0 ||
                  fullName.length === 0 ||
                  password.length < 8 ||
                  passwordRepeat.length < 8 ||
                  password !== passwordRepeat
                    ? "#86cb1757"
                    : "#1dcb17",
                border: "none",
                cursor: "pointer",
                color: "#FFFFFF",
                fontWeight: "bold",
                height: "56px",
                width: "300px",
                borderRadius: "10px",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImgSgv
                style={{ marginRight: "5px" }}
                width={18}
                src="/icons/menu/new.svg"
              />
              Criar
            </button>
          </div>
          <br />
        </div>
      )}

      {userData && userId !== "New" && (
        <>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              id="email"
              label="E-mail"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              id="fullName"
              label="Nome Completo"
              variant="outlined"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="nickname"
              label="Apelido"
              variant="outlined"
              value={userData?.profile?.nickname || ""}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="birthdate"
              label="Data nascimento"
              variant="outlined"
              type="datetime"
              // dia em GMT-0 visto que o banco ta salvando e retornando em GMT-0
              value={
                userData?.profile?.birthdate
                  ? format(
                      new Date(
                        new Date(userData?.profile?.birthdate).getFullYear(),
                        new Date(userData?.profile?.birthdate).getMonth(),
                        new Date(userData?.profile?.birthdate).getDate() + 1
                      ),
                      "dd/MM/yyyy"
                    )
                  : ""
              }
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="ddiMobilePhone"
              label="DDD + Celular"
              variant="outlined"
              type="tel"
              value={
                (userData?.profile?.ddiMobilePhone || "") +
                (userData?.profile?.mobilePhone || "")
              }
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="addressCity"
              label="Cidade"
              variant="outlined"
              type="text"
              value={userData?.profile?.address?.city?.name || ""}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="addressState"
              label="Estado"
              variant="outlined"
              type="text"
              value={userData?.profile?.address?.state?.name || ""}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="addressCountry"
              label="Pais"
              variant="outlined"
              type="text"
              value={userData?.profile?.address?.country?.name || ""}
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="nativeLanguage"
              label="Linguagem Nativa"
              variant="outlined"
              type="text"
              value={userData?.profile?.nativeLanguage?.name || ""}
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="profileRelationshipLanguage"
              label="Linguagem de interesse"
              variant="outlined"
              type="text"
              value={
                userData?.profile?.profileRelationshipLanguage?.map(
                  (item) => " " + item?.relationshipLanguage?.name || ""
                ) || ""
              }
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="profileKnowledgeAndInterests"
              label="Assuntos de interesses"
              variant="outlined"
              type="text"
              value={
                userData?.profile?.profileKnowledgeAndInterests?.map(
                  (item) => " " + item?.knowledgeAndInterests?.name || ""
                ) || ""
              }
            />
            <TextField
              disabled
              style={{ width: "100%", paddingRight: "20px" }}
              id="profileInterestSubjects"
              label="Assuntos de interesse"
              variant="outlined"
              type="text"
              value={
                userData?.profile?.profileInterestSubjects?.map(
                  (item) => " " + item?.knowledgeAndInterests?.name || ""
                ) || ""
              }
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <RadioGroup value={userData?.isActive} row>
              <FormLabel>Usuário Status</FormLabel>
              <div style={{ width: "100%", display: "flex" }}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Desbloqueado"
                  onClick={() => {
                    Swal.fire({
                      title: "Tem certeza que deseja desbloquear o usuário?",
                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: "Sim",
                      denyButtonText: "Não",
                      confirmButtonColor: "#63adb7",
                      denyButtonColor: "#b96ea5",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        putAppUserUnblock(userData.id);
                      }
                    });
                  }}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Bloqueado"
                  onClick={() => {
                    Swal.fire({
                      title: "Tem certeza que deseja bloquear o usuário?",
                      showDenyButton: true,
                      showCancelButton: false,
                      confirmButtonText: "Sim",
                      denyButtonText: "Não",
                      confirmButtonColor: "#63adb7",
                      denyButtonColor: "#b96ea5",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        putAppUserBlock(userData.id);
                      }
                    });
                  }}
                />
              </div>
            </RadioGroup>
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              id="password"
              label="Senha"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <TextField
              style={{ width: "100%", paddingRight: "20px" }}
              id="passwordRepeat"
              label="Repita senha"
              variant="outlined"
              type="password"
              value={passwordRepeat}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
              }}
            />
            <button
              onClick={EditarUserAdmin}
              disabled={
                email.length === 0 ||
                fullName.length === 0 ||
                (password.length < 8 && password.length > 0) ||
                (passwordRepeat.length < 8 && passwordRepeat.length > 0) ||
                password !== passwordRepeat
              }
              style={{
                backgroundColor:
                  email.length === 0 ||
                  fullName.length === 0 ||
                  (password.length < 8 && password.length > 0) ||
                  (passwordRepeat.length < 8 && passwordRepeat.length > 0) ||
                  password !== passwordRepeat
                    ? "#86cb1757"
                    : "#1dcb17",
                border: "none",
                cursor: "pointer",
                color: "#FFFFFF",
                fontWeight: "bold",
                height: "56px",
                width: "300px",
                borderRadius: "10px",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <ImgSgv
                style={{ marginRight: "5px" }}
                width={18}
                src="/icons/menu/new.svg"
              />
              Editar
            </button>
            {!userData?.roles?.includes("ROLE_ADMIN") && (
              <button
                onClick={() => {
                  Swal.fire({
                    title:
                      "Tem certeza que deseja promover o usuário a Administrador?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Sim",
                    denyButtonText: "Não",
                    confirmButtonColor: "#63adb7",
                    denyButtonColor: "#b96ea5",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      putAppUserPromote(userData?.id as string).then(() => {
                        window.location.reload();
                      });
                    }
                  });
                }}
                style={{
                  backgroundColor: "#6275ca",
                  border: "none",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  height: "56px",
                  width: "500px",
                  borderRadius: "10px",
                  alignContent: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "20px",
                }}
              >
                Promover a admin
              </button>
            )}
          </div>
        </>
      )}
      <br />
    </div>
  );
};

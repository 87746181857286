import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { digitalApiUrl, digitalAssetsUrl } from "../../../Services/Api";
import {
  getBannerID,
  postBannerNew,
  putBanner,
  uploadImageBanner,
} from "../../../Services/Banner";
import { IRestGetIBanner } from "../../../Services/Interfaces";
import { TextH2 } from "../../../styles/global.styles";
import { ImgSgv } from "../../Login/stylesLogin";

enum TypeLink {
  INTERNAL = "y",
  EXTERNAL = "n",
}

export const BannerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { bannerId } = useParams();

  const [name, setName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [filename, setFilename] = useState<string>(
    digitalAssetsUrl + "/assets/banner.jpg"
  );
  const [displayOrder, setDisplayOrder] = useState<string>("");
  const [line, setLine] = useState<string>("");
  const [url, setUrl] = useState("");
  const [internalUrl, setInternalUrl] = useState<TypeLink>(TypeLink.EXTERNAL);

  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const isEditing = bannerId !== "New";

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const criarBanner = () => {
    const bannerData =
      url.trim().length <= 0
        ? {
            name,
            title,
            displayOrder,
            line,
          }
        : {
            name,
            title,
            displayOrder,
            line,
            url,
            internalUrl,
          };

    if (imageFile) {
      postBannerNew(bannerData).then((res) => {
        const { data } = res as IRestGetIBanner;
        const formData = new FormData();
        formData.append("upload[uploadFile][file]", imageFile);
        uploadImageBanner(data.data.id, formData).then(() => {
          Rota(`/banner`);
        });
      });
    } else {
      alert("Selecione uma imagem");
    }
  };

  const EditarBanner = () => {
    if (bannerId !== "New" && bannerId !== undefined) {
      const bannerData =
        url.trim().length <= 0
          ? {
              displayOrder,
              isActive: isActive ? "active" : "inactive",
            }
          : {
              displayOrder,
              isActive: isActive ? "active" : "inactive",
              url,
              internalUrl,
            };

      putBanner(bannerId, bannerData).then(() => {
        if (imageFile) {
          const formData = new FormData();
          formData.append("upload[uploadFile][file]", imageFile);
          uploadImageBanner(bannerId, formData).then(() => {
            Rota(`/banner`);
          });
        } else {
          Rota(`/banner`);
        }
      });
    }
  };

  useEffect(() => {
    if (
      bannerId &&
      bannerId !== "New" &&
      location.pathname.split("/")[1] === "banner"
    ) {
      getBannerID(bannerId).then((response) => {
        const { data } = response as IRestGetIBanner;
        setLine(data.data.line);
        setDisplayOrder(data.data.displayOrder);
        setFilename(
          data.data.filename
            ? digitalApiUrl + data.data.filename
            : digitalAssetsUrl + "/assets/banner.jpg"
        );
        setIsActive(data.data.isActive);
        setTitle(data.data.title);
        setName(data.data.name);
        setUrl(data.data.url);
        setInternalUrl((data.data.internalUrl ? "y" : "n") as TypeLink);
      });
    }
  }, [location.pathname, bannerId]);

  useEffect(() => {
    internalUrl === "n" && setUrl("");
  }, [internalUrl]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
    }
  };

  return (
    <Stack spacing={2}>
      <TextH2>{isEditing ? "Editar Banner" : "Novo Banner"}</TextH2>

      <Grid container spacing={2} width={"1000px"}>
        <Grid xs={6} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
              paddingRight: "20px",
              paddingBottom: "20px",
            }}
            autoComplete="off"
            id="name"
            label="Nome"
            variant="outlined"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid xs={6} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
              paddingBottom: "20px",
            }}
            autoComplete="off"
            id="displayOrder"
            label="Ordem de exibição"
            variant="outlined"
            type="number"
            value={displayOrder}
            onChange={(e) => setDisplayOrder(e.target.value)}
          />
        </Grid>
        <Grid xs={6} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
              paddingRight: "20px",
              paddingBottom: "20px",
            }}
            autoComplete="off"
            id="title"
            label="Titulo (Opcional)"
            variant="outlined"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid xs={6} item paddingLeft={"0px !important"}>
          <TextField
            style={{
              width: "100%",
              paddingBottom: "20px",
            }}
            autoComplete="off"
            id="line"
            label="Linha"
            variant="outlined"
            type="number"
            value={line}
            onChange={(e) => setLine(e.target.value)}
          />
        </Grid>
        <Grid xs={4} item paddingLeft={"0px !important"}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tipo do link</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={internalUrl}
              label="Tipo da busca"
              defaultChecked
              onChange={(e) => setInternalUrl(e.target.value as TypeLink)}
            >
              <MenuItem value={TypeLink.INTERNAL}>Link interno</MenuItem>
              <MenuItem value={TypeLink.EXTERNAL}>Link externo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {internalUrl === TypeLink.INTERNAL && (
          <Grid xs={8} item paddingBottom={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Página interna
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={url}
                label="Página interna"
                defaultChecked
                onChange={(e) => setUrl(e.target.value)}
              >
                <MenuItem value={"MAPS"}>Mapas</MenuItem>
                <MenuItem value={"Networking"}>Networkings</MenuItem>
                <MenuItem value={"Explore"}>Explorar</MenuItem>
                <MenuItem value={"Messages"}>Mensagens</MenuItem>
                <MenuItem value={"About"}>Sobre</MenuItem>
                <MenuItem value={"Account"}>Minha Conta</MenuItem>
                <MenuItem value={"Support"}>Suporte</MenuItem>
                <MenuItem value={"Policy"}>Política de privacidade</MenuItem>
                <MenuItem value={"Events"}>Eventos</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {internalUrl === TypeLink.EXTERNAL && (
          <Grid xs={8} item paddingBottom={1}>
            <TextField
              style={{
                width: "100%",
                paddingRight: "20px",
              }}
              autoComplete="off"
              id="url"
              label="URL"
              variant="outlined"
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
        )}
        <Grid xs={6} item paddingLeft={"0px !important"}>
          <FormControlLabel
            control={
              <Switch
                disabled
                style={{
                  width: "100%",
                  paddingRight: "20px",
                  paddingBottom: "20px",
                }}
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
            }
            label="Ativar Banner"
          />
          <img
            width={420}
            height={150}
            src={imageFile ? URL.createObjectURL(imageFile) : filename}
            alt="Banner"
          />
        </Grid>
        <Grid xs={6} item paddingLeft={"0px !important"}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            {imageFile ? imageFile.name : "Enviar Banner 420x150 (JPG, PNG)"}
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
          <button
            onClick={() => {
              isEditing ? EditarBanner() : criarBanner();
            }}
            disabled={
              line.length === 0 ||
              displayOrder.length === 0 ||
              name.length === 0
            }
            style={{
              backgroundColor:
                line.length === 0 ||
                displayOrder.length === 0 ||
                name.length === 0
                  ? "#86cb1757"
                  : "#1dcb17",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "56px",
              width: "100px",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ImgSgv
              style={{ marginRight: "5px" }}
              width={18}
              src="/icons/menu/new.svg"
            />
            {isEditing ? "Salvar" : "Criar"}
          </button>
        </Grid>
      </Grid>
    </Stack>
  );
};

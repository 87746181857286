import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import {
  IErroRest,
  IRestGetAdminAppUserID,
  IRestPaginate,
  IRestUserAdminCreate,
} from "./Interfaces";

export const getAdminUser = async (
  offset: number,
  limit: number,
  email: string = "",
  fullName: string = "",
  xlsx: boolean = false
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/admin_user?offset=${offset}&limit=${limit}${
              xlsx ? "&export=1" : ""
            }${email ? "&criteria[user.email]=" + email : ""}${
              fullName ? "&criteria[profile.fullName]=" + fullName : ""
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: xlsx ? "blob" : "json",
            }
          )
          .then((response: IRestPaginate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getAdminAppUserID = async (ID: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`admin/app_user/${ID}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IRestGetAdminAppUserID) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putAppUserPromote = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(
            `/admin/admin_user/${id}/promote`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestPaginate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Promovendo usuário...",
        success: "Usuário promovido.",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putAdminUserDisable = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .delete(`/admin/admin_user/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IRestPaginate) => {
            window.location.reload();
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Desabilitando admin...",
        success: "Admin desabilitado.",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const postAdminUserCreate = async (
  email: string,
  password: string,
  fullName: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post(
            `/admin/admin_user`,
            {
              email,
              password,
              profile: {
                fullName: fullName,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestUserAdminCreate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Criando admin...",
        success: {
          render(ToastContentProps) {
            const { data } =
              ToastContentProps.data as unknown as IRestUserAdminCreate;
            return data.message;
          },
        },
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putAdminUserEdit = async (
  id: string,
  email: string,
  password: string,
  fullName: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        const data = password
          ? {
              email,
              password,
              profile: {
                fullName: fullName,
              },
            }
          : {
              email,
              profile: {
                fullName: fullName,
              },
            };
        api
          .put(`/admin/admin_user/${id}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IRestUserAdminCreate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Criando admin...",
        success: {
          render(ToastContentProps) {
            const { data } =
              ToastContentProps.data as unknown as IRestUserAdminCreate;
            return data.message;
          },
        },
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

import { styled } from "@mui/material/styles";

export const TextH2 = styled("h2")`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;

import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridDeleteIcon, ptBR } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteEvent, getEventAll } from "../../../Services/Event";
import { IEvent, IRestPaginate } from "../../../Services/Interfaces";
import { TextH2 } from "../../../styles/global.styles";
import { ImgSgv } from "../../Login/stylesLogin";
import { IconButton } from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import { format, toDate } from "date-fns";

export const Event = () => {
  const navigate = useNavigate();

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const [establishment, setEstablishment] = useState<IEvent[]>([]);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });

  useEffect(() => {
    setIsLoading(true);
    getEventAll(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.pageSize
    ).then((response) => {
      setIsLoading(false);
      const { data } = response as IRestPaginate;
      setPageSize(data.pagination.total);
      setEstablishment(data.data);
    });
  }, [paginationModel]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      type: "string",
      minWidth: window.innerWidth / 5,
    },
    {
      field: "description",
      headerName: "Descrição",
      type: "string",
      minWidth: window.innerWidth / 5,
    },
    {
      field: "eventStartDate",
      headerName: "Data inicial",
      type: "string",
      minWidth: window.innerWidth / 10,
      renderCell: (params) => {
        const date = format(
          toDate(params.row.eventStartDate),
          "dd/MM/yyyy HH:mm"
        );
        return date;
      },
    },
    {
      field: "eventEndDate",
      headerName: "Data final",
      type: "string",
      minWidth: window.innerWidth / 10,
      renderCell: (params) => {
        const date = format(
          toDate(params.row.eventEndDate),
          "dd/MM/yyyy HH:mm"
        );
        return date;
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Box display={"flex"} gap={2}>
            <IconButton aria-label="editar" onClick={() => Rota(params.row.id)}>
              <ZoomIn />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                Swal.fire({
                  title: "Tem certeza que deseja excluir este evento?",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: "Sim",
                  denyButtonText: "Não",
                  confirmButtonColor: "#b96e8b",
                  denyButtonColor: "#63adb7",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteEvent(params.row.id).then(() => {
                      window.location.reload();
                    });
                  }
                });
              }}
            >
              <GridDeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          alignContent: "space-between",
          alignItems: "space-between",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextH2
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "start",
            textAlign: "center",
            width: "100%",
          }}
        >
          <ImgSgv width={24} src="/icons/menu/visao.svg" />
          &nbsp;Eventos
        </TextH2>
        <button
          onClick={() => Rota("New")}
          style={{
            backgroundColor: "#9c9b9f",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "128px",
            borderRadius: "10px",
            paddingRight: "10px",
            marginRight: "20px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px", marginLeft: "10px" }}
            width={18}
            src="/icons/menu/new.svg"
          />
          Cadastrar
        </button>
      </div>
      <br />
      <Box sx={{ height: "90%", width: "100%" }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={establishment!}
          columns={columns}
          rowCount={pageSize}
          loading={isLoading}
          pageSizeOptions={[20, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </>
  );
};

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";

type HealthPlaceProfileTextProps = {
  primary: string;
};

export const LoginContent = styled("div")<HealthPlaceProfileTextProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0;
  background-color: ${(props) => props.primary};
`;

export const LoginDiv = styled("div")`
  background-color: #fafafa;
  border-radius: 10px;
  height: 50%;
  width: 100%;
  max-width: 541px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-top: -10%;
  position: relative;
  margin-bottom: 12%;
`;

export const ForgotLoginDiv = LoginDiv;

export const LoginTituloDiv = styled("div")`
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #000000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 50px;
  width: 100%;
  border-color: #000000;
`;

export const LoginB6H2 = styled("h2")`
  color: #00b6bc;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const LoginFFH2 = styled("h2")`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ImgSgv = styled("img")``;

export const DivFormLogin = styled("form")`
  align-items: center;
  padding: 37px;
  @media (max-width: 700px) {
    padding: 37px 20px;
  }
`;

export const LoginFormControl = styled(FormControl)`
  width: 100%;
`;

export const LoginInputLabel = styled(InputLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #717171;
`;

export const LoginOutlinedInput = styled(OutlinedInput)`
  background-color: #ffffff;
  border-radius: 6px;
`;
export const LoginInputAdornment = styled(InputAdornment)``;

export const LoginIconButton = styled(IconButton)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #717171;
`;

export const LoginContentButton = styled("div")`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const LoginButton = styled("button")`
  border: none;
  margin-top: 15px;
  width: 100%;
  background: #00b6bc;
  border-top: 15px solid #00b6bc;
  border-bottom: 15px solid #00b6bc;
  border-radius: 6px;
  background-color: #00b6bc;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 4px 6px 0px,
    rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  border-radius: 6px;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #1f1f1f;
  &:disabled {
    background-color: #00b6bc;
    border-top: 15px solid #00b6bc;
    border-bottom: 15px solid #00b6bc;
    cursor: not-allowed;
  }
  &:hover {
    background-color: #00a4aa;
    border-top: 15px solid #00a4aa;
    border-bottom: 15px solid #00a4aa;
  }
`;

export const LoginContentA = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

export const LoginA = styled("a")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  text-decoration-line: underline;

  color: #717171;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #00b6bc;
  }
`;

export const LoginLogoContent = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6%;
`;

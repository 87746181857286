import React, { ReactElement } from "react";
import Avatar from "react-avatar";

import {
  ComponentContent,
  CopyrightContainer,
  Header,
  HeaderContent,
  LinkA,
  LogoMenu,
  MenuContainer,
  MenuContainerHeader,
  MenuContainerSection,
  Sidebar,
  SidebarHeaderContainer,
  SidebarMenu,
  HideItemDesk,
  ImgSgv,
  LoginFFH3,
  MenuTituloDiv,
  MenuLinearGradienteDow,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../Components/Auth/isAuthenticated";
import { getAppUserMe } from "../../Services/AppUser";
import { IRestGetUserMe } from "../../Services/Interfaces";
import { baseURL } from "../../Services/Api";

interface PageHeaderProps {
  InternalComponent: ReactElement<any, any>;
}

const PageHeaderUser: React.FC<PageHeaderProps> = ({ InternalComponent }) => {
  const navigate = useNavigate();

  const getName = () => {
    return localStorage.getItem("fullName") || "Name";
  };

  const RotaUser = (path: string) => {
    getAppUserMe().then((response) => {
      const { data } = response as IRestGetUserMe;
      if (data) {
        navigate(`${path}/${data.data.id}`, { replace: true });
      }
    });
  };

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const LogOut = () => {
    Logout();
    return navigate("/login", { replace: true });
  };

  return (
    <SidebarHeaderContainer
      firstBackgroundColor={"#05C6C7"}
      secondBackgroundColor={"#282727"}
    >
      <Sidebar
        firstBackgroundColor={"#282727"}
        secondBackgroundColor={"#282727"}
      >
        <SidebarMenu>
          <HideItemDesk>
            <LogoMenu
              onClick={() => Rota("/home")}
              src={"/Pertalks.svg"}
              alt="Prospera"
            />
          </HideItemDesk>
          <MenuContainer>
            <MenuContainerSection onClick={() => Rota("/home")}>
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv
                    style={{ paddingTop: 4 }}
                    width={18}
                    src="/icons/menu/visao.svg"
                  />
                  <LoginFFH3>&nbsp;&nbsp;Visão Geral&nbsp;</LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
            <MenuContainerSection onClick={() => Rota("/banner")}>
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv
                    style={{ paddingTop: 4 }}
                    width={18}
                    src="/icons/menu/visao.svg"
                  />
                  <LoginFFH3>&nbsp;&nbsp;Banner&nbsp;</LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
            <MenuContainerSection onClick={() => Rota("/eventos")}>
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv
                    style={{ paddingTop: 4 }}
                    width={18}
                    src="/icons/menu/visao.svg"
                  />
                  <LoginFFH3>&nbsp;&nbsp;Eventos&nbsp;</LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
            <MenuContainerSection onClick={() => Rota("/UserManagement")}>
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv
                    style={{ paddingTop: 4 }}
                    width={18}
                    src="/icons/menu/usuarios.svg"
                  />
                  <LoginFFH3>&nbsp;&nbsp;Usuários Aplicativo&nbsp;</LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
            <MenuContainerSection onClick={() => Rota("/UserAdminRegister")}>
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv width={14} src="/icons/menu/usuario.svg" />
                  <LoginFFH3>
                    &nbsp;&nbsp;Usuários Administrativos &nbsp;
                  </LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
            <MenuContainerSection
              onClick={() => Rota("/EstablishmentsRegistration")}
            >
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv width={18} src="/icons/menu/estabelecimento.svg" />
                  <LoginFFH3>&nbsp;&nbsp;Estabelecimentos&nbsp;</LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
            <MenuContainerSection onClick={() => Rota("/PlatformReport")}>
              <MenuContainerHeader>
                <MenuTituloDiv>
                  <ImgSgv width={18} src="/icons/menu/plataforma.svg" />
                  <LoginFFH3>&nbsp;&nbsp;Relatórios&nbsp;</LoginFFH3>
                </MenuTituloDiv>
              </MenuContainerHeader>
              <MenuLinearGradienteDow />
            </MenuContainerSection>
          </MenuContainer>
        </SidebarMenu>
        <CopyrightContainer>
          <LinkA
            primary={"#00b6bc"}
            target="_blank"
            href={baseURL + "/politica.html"}
          >
            Termo de Uso e Política de Proteção <br />
            de Dados e Privacidade
          </LinkA>
        </CopyrightContainer>
      </Sidebar>
      <HeaderContent>
        <Header>
          <HideItemDesk>
            <Avatar
              style={{
                cursor: "pointer",
              }}
              onClick={() => RotaUser("/UserAdminRegister")}
              name={getName()}
              size="58"
              round={true}
              color="#b96ea5"
            />
            <LinkA
              style={{
                paddingLeft: 10,
              }}
              onClick={LogOut}
              primary={"#FFFFFF"}
            >
              Sair
            </LinkA>
          </HideItemDesk>
        </Header>
        <ComponentContent>{InternalComponent}</ComponentContent>
      </HeaderContent>
    </SidebarHeaderContainer>
  );
};

export default PageHeaderUser;

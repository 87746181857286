import { TextH2 } from "../../../styles/global.styles";
import { useEffect, useState } from "react";

import {
  getReportCustomerNetworking,
  getReportEstablishment,
  getReportUser,
} from "../../../Services/Report";
import {
  IRestGraphicEstablishment,
  IRestGraphicReportCustomerNetworking,
  IRestGraphicReportUser,
} from "../../../Services/Interfaces";
import { ImgSgv } from "../../Login/stylesLogin";
import dayjs from "dayjs";
import { BoxReport } from "./Box";

export const Overview = () => {
  const today = dayjs(); // Obtém a data atual
  const startOfMonth = today.subtract(1, "month").startOf("month"); // Primeiro dia do mês anterior
  const endOfMonth = today.subtract(1, "month").endOf("month"); // Último dia do mês anterior
  const [filterStartEnd] = useState({
    start: startOfMonth.format("YYYY-MM-DD"),
    end: endOfMonth.format("YYYY-MM-DD"),
  });

  const [dataGeneral, setGeneral] = useState<{
    accepted: number;
    not_accepted: number;
    canceled: number;
  }>({
    accepted: 0,
    not_accepted: 0,
    canceled: 0,
  });

  useEffect(() => {
    getReportEstablishment(filterStartEnd.start, filterStartEnd.end).then(
      (response) => {
        const { data } = response as IRestGraphicEstablishment;
        setEstablishment({ total: data.data.total });
      }
    );

    getReportUser(filterStartEnd.start, filterStartEnd.end).then((response) => {
      const { data } = response as IRestGraphicReportUser;
      setReportUser({
        active: data.data.active,
        blocked: data.data.blocked,
        deleted: data.data.deleted,
      });
    });

    getReportCustomerNetworking(filterStartEnd.start, filterStartEnd.end).then(
      (response) => {
        const { data } = response as IRestGraphicReportCustomerNetworking;
        const dataResponse = [
          [
            { type: "date", label: "Mes" },
            "Meetings Aceitos",
            "Meetings Cancelados",
            "Meetings não aceitos",
          ],
        ] as any;
        data.data.monthly.map((item) => {
          return dataResponse.push([
            new Date(+item.year, +item.month - 1),
            +item.accepted,
            +item.canceled,
            +item.not_accepted,
          ]);
        });
        setGeneral(data.data.general);
      }
    );
  }, [filterStartEnd.end, filterStartEnd.start]);

  const [dataEstablishment, setEstablishment] = useState<{ total: number }>({
    total: 0,
  });

  const [dataReportUser, setReportUser] = useState<{
    blocked: number;
    active: number;
    deleted: number;
  }>({
    blocked: 0,
    active: 0,
    deleted: 0,
  });

  function UserStatusArea(active: number, blocked: number, deleted: number) {
    const tableStyle: React.CSSProperties = {
      display: "grid",
      gridTemplateRows: "auto auto", // Duas linhas
      gridTemplateColumns: "1fr 1fr 1fr", // tres colunas
      gap: "10px",
      paddingTop: "20px",
      color: "white",
    };

    const cellStyle: React.CSSProperties = {
      padding: "10px",
      textAlign: "center", // Defina como 'center'
      backgroundColor: "#0F787F",
    };

    const totalCellStyle: React.CSSProperties = {
      ...cellStyle,
      gridColumn: "span 3", // Ocupa duas colunas
    };

    return (
      <div style={tableStyle}>
        <div style={totalCellStyle}>
          <h4>Total de Usuários</h4>
          <h1>{active + blocked}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Bloqueados</h4>
          <h1>{blocked}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Ativos</h4>
          <h1>{active}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Deletados</h4>
          <h1>{deleted}</h1>
        </div>
      </div>
    );
  }

  function MeetsGeral(
    Meets: {
      accepted: number;
      not_accepted: number;
      canceled: number;
    } = dataGeneral
  ) {
    const tableStyle: React.CSSProperties = {
      display: "grid",
      gridTemplateRows: "auto auto", // Duas linhas
      gridTemplateColumns: "1fr 1fr", // Duas colunas
      gap: "10px",
      paddingTop: "20px",
      color: "white",
    };

    const cellStyle: React.CSSProperties = {
      padding: "10px",
      textAlign: "center", // Defina como 'center'
      backgroundColor: "#0F787F",
    };

    const totalCellStyle: React.CSSProperties = {
      ...cellStyle,
      gridColumn: "span 2", // Ocupa duas colunas
    };

    return (
      <div style={tableStyle}>
        <div style={totalCellStyle}>
          <h4>Meets Aceitos</h4>
          <h1>{Meets.accepted}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Meets Cancelados</h4>
          <h1>{Meets.canceled}</h1>
        </div>
        <div style={cellStyle}>
          <h4>Meets não aceitos</h4>
          <h1>{Meets.not_accepted}</h1>
        </div>
      </div>
    );
  }

  function Establishment(total: number) {
    const tableStyle: React.CSSProperties = {
      display: "grid",
      gridTemplateRows: "auto auto", // Duas linhas
      gridTemplateColumns: "1fr 1fr", // Duas colunas
      gap: "10px",
      paddingTop: "20px",
      color: "white",
    };

    const cellStyle: React.CSSProperties = {
      padding: "10px",
      textAlign: "center", // Defina como 'center'
      backgroundColor: "#0F787F",
    };

    const totalCellStyle: React.CSSProperties = {
      ...cellStyle,
      gridColumn: "span 2", // Ocupa duas colunas
    };

    return (
      <div style={tableStyle}>
        <div style={totalCellStyle}>
          <h4>Total</h4>
          <h1>{total}</h1>
        </div>
      </div>
    );
  }

  return (
    <div>
      <br />
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <ImgSgv
          style={{ marginRight: "10px" }}
          width={26}
          src="/icons/menu/visao.svg"
        />
        <TextH2>Visão Geral</TextH2>
      </div>
      <br />
      <p
        style={{
          color: "#0F787F",
          fontWeight: "bold",
          paddingLeft: "10px",
        }}
      >
        Período filtrado:{" "}
        {dayjs(filterStartEnd.start, "YYYY-MM-DD").format("DD/MM/YYYY")} a{" "}
        {dayjs(filterStartEnd.end, "YYYY-MM-DD").format("DD/MM/YYYY")}
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          alignContent: "space-between",
          width: "100%",
        }}
      >
        <BoxReport
          InternalComponent={UserStatusArea(
            dataReportUser.active,
            dataReportUser.blocked,
            dataReportUser.deleted
          )}
          title={"Usuários"}
          ImgSgvIco="/icons/menu/usuarios.svg"
        />
        <BoxReport
          InternalComponent={Establishment(dataEstablishment.total)}
          title={"Estabelecimentos"}
          ImgSgvIco="/icons/menu/estabelecimento.svg"
        />
        <BoxReport
          InternalComponent={MeetsGeral()}
          title={"Meetings"}
          ImgSgvIco="/icons/menu/meets.svg"
        />
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, ptBR, GridColDef } from "@mui/x-data-grid";
import { TextH2 } from "../../../styles/global.styles";
import {
  getAppUser,
  putAppUserBlock,
  putAppUserUnblock,
} from "../../../Services/AppUser";
import { IAppUse, IRestPaginate } from "../../../Services/Interfaces";
import { ImgSgv } from "../../Login/stylesLogin";
import { AxiosResponse } from "axios";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const UserManagement = () => {
  const navigate = useNavigate();

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };
  const [appUse, setAppUse] = useState<IAppUse[]>([]);
  const [pageSize, setPageSize] = useState(20);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [filterModel, setFilterModel] = useState({ email: "", fullName: "" });

  useEffect(() => {
    setIsLoading(true);
    getAppUser(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.pageSize,
      filterModel.email,
      filterModel.fullName
    ).then((response) => {
      setIsLoading(false);
      const { data } = response as IRestPaginate;
      setPageSize(data.pagination.total);
      setAppUse(data.data);
    });
  }, [filterModel.email, filterModel.fullName, paginationModel]);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      minWidth: window.innerWidth / 7,
    },
    {
      field: "profile.fullName",
      headerName: "Nome",
      type: "string",
      minWidth: window.innerWidth / 7,
      valueGetter: (params) => params?.row?.profile?.fullName || "",
    },
    {
      field: "profile.address.country.name",
      headerName: "País",
      type: "string",
      minWidth: window.innerWidth / 9,
      valueGetter: (params) =>
        params?.row?.profile?.address?.country?.name || "",
    },
    {
      field: "profile.address.city.name",
      headerName: "Cidade ",
      type: "string",
      minWidth: window.innerWidth / 9,
      valueGetter: (params) => params?.row?.profile?.address?.city?.name || "",
    },
    {
      field: "edit",
      headerName: "Visualizar",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <button
            onClick={() => Rota(params.row.id)}
            style={{
              backgroundColor: "#63adb7",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "80%",
              width: "100%",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImgSgv
              style={{ margin: "5px" }}
              width={18}
              src="/icons/menu/gerenciamento.svg"
            />
            Visualizar
          </button>
        );
      },
    },
    {
      field: "Bloquear",
      headerName: "Bloquear",
      minWidth: 150,
      renderCell: (params) => {
        if (params.row.isActive === true) {
          return (
            <button
              onClick={() => {
                Swal.fire({
                  title:
                    "Tem certeza que deseja bloquear o usuário do aplicativo?",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: "Sim",
                  denyButtonText: "Não",
                  confirmButtonColor: "#63adb7",
                  denyButtonColor: "#b96ea5",
                }).then((result) => {
                  if (result.isConfirmed) {
                    putAppUserBlock(params.row.id);
                  }
                });
              }}
              style={{
                backgroundColor: "#b96ea5",
                border: "none",
                cursor: "pointer",
                color: "#FFFFFF",
                fontWeight: "bold",
                height: "80%",
                width: "90%",
                borderRadius: "10px",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Bloquear
            </button>
          );
        } else {
          return (
            <button
              onClick={() => {
                Swal.fire({
                  title:
                    "Tem certeza que deseja desbloquear o usuário do aplicativo?",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: "Sim",
                  denyButtonText: "Não",
                  confirmButtonColor: "#63adb7",
                  denyButtonColor: "#b96ea5",
                }).then((result) => {
                  if (result.isConfirmed) {
                    putAppUserUnblock(params.row.id);
                  }
                });
              }}
              style={{
                backgroundColor: "#baca62",
                border: "none",
                cursor: "pointer",
                color: "#FFFFFF",
                fontWeight: "bold",
                height: "80%",
                width: "90%",
                borderRadius: "10px",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Desbloquear
            </button>
          );
        }
      },
    },
    // {
    //   field: "Promover",
    //   headerName: "Promover",
    //   minWidth: 180,
    //   renderCell: (params) => {
    //     return (
    //       <button
    //         onClick={() => {
    //           putAppUserPromote(params.row.id);
    //         }}
    //         style={{
    //           backgroundColor: "#6275ca",
    //           border: "none",
    //           cursor: "pointer",
    //           color: "#FFFFFF",
    //           fontWeight: "bold",
    //           height: "80%",
    //           width: "90%",
    //           borderRadius: "10px",
    //         }}
    //       >
    //         Promover a admin
    //       </button>
    //     );
    //   },
    // },
  ];

  const handleFilterEnter = () => {
    setPaginationModel({ page: 0, pageSize: 20 });
    setFilterModel({ email, fullName: name });
  };

  return (
    <>
      <div
        style={{
          alignContent: "space-between",
          alignItems: "space-between",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextH2
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "start",
            textAlign: "center",
            width: "100%",
          }}
        >
          <ImgSgv width={24} src="/icons/menu/usuarios.svg" />
          &nbsp;Usuários Aplicativo
        </TextH2>
        <button
          onClick={() => {
            getAppUser(
              paginationModel.page * paginationModel.pageSize,
              paginationModel.pageSize,
              filterModel.email,
              filterModel.fullName,
              true
            ).then((response) => {
              const dow = response as AxiosResponse<Blob>;
              if (dow.status === 200) {
                const downloadUrl = window.URL.createObjectURL(dow.data);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute(
                  "download",
                  `AppUser-${new Date().getTime()}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
              }
            });
          }}
          style={{
            backgroundColor: "#63adb7",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "120px",
            borderRadius: "10px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px" }}
            width={18}
            src="/icons/menu/export.svg"
          />
          Exportar
        </button>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          style={{ width: "100%", paddingRight: "20px" }}
          id="name"
          label="Nome"
          variant="outlined"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleFilterEnter();
          }}
        />
        <TextField
          style={{ width: "100%", paddingRight: "20px" }}
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleFilterEnter();
          }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <button
          onClick={() => {
            setName("");
            setEmail("");
            setFilterModel({ email: "", fullName: "" });
          }}
          style={{
            backgroundColor: "#b96ea5",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "260px",
            marginRight: "20px",
            borderRadius: "10px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px" }}
            width={18}
            src="/icons/menu/filter_clear.svg"
          />
          Limpar
        </button>
        <button
          onClick={() => {
            handleFilterEnter();
          }}
          style={{
            backgroundColor: "#000000",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "260px",
            borderRadius: "10px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px" }}
            width={18}
            src="/icons/menu/filter.svg"
          />
          Filtrar
        </button>
      </div>
      <br />
      <Box sx={{ height: "90%", width: "100%" }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={appUse!}
          columns={columns}
          rowCount={pageSize}
          loading={isLoading}
          pageSizeOptions={[20, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </>
  );
};

import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import { IErroRest, IRest } from "./Interfaces";
import { getAppUserMe } from "./AppUser";

export const loginCheck = async (username: string, password: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("pt_BR/login_check", {
            username: username,
            password: password,
          })
          .then((response) => {
            if (response.data?.token)
              localStorage.setItem("token", response.data.token);
            getAppUserMe()
              .then(() => {
                return resolve(response.data.token);
              })
              .catch((error) => {
                return reject(error);
              });
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Efetuando Login",
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const rememberPassword = async (username: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .post("pt_BR/remember_password", {
            email: username,
          })
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const rememberPasswordCode = async (code: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`pt_BR/remember_password/${code}`)
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const rememberPasswordNew = async (
  code: string,
  password: string,
  repeatPassword: string
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(`pt_BR/remember_password/${code}`, {
            password: password,
            repeatPassword: repeatPassword,
          })
          .then((response) => {
            return resolve(response.data);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Alterando Senha",
        success: {
          render(ToastContentProps) {
            const { data } = ToastContentProps as unknown as IRest;
            return data.message;
          },
        },
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, ptBR, GridColDef } from "@mui/x-data-grid";
import { TextH2 } from "../../../styles/global.styles";
import { IBanner, IRestPaginate } from "../../../Services/Interfaces";
import { ImgSgv } from "../../Login/stylesLogin";
import { useNavigate } from "react-router-dom";
import {
  deleteBanner,
  getBannerAll,
  putBanner,
} from "../../../Services/Banner";
import Swal from "sweetalert2";

export const Banner = () => {
  const navigate = useNavigate();

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };

  const [establishment, setEstablishment] = useState<IBanner[]>([]);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });

  useEffect(() => {
    setIsLoading(true);
    getBannerAll(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.pageSize
    ).then((response) => {
      setIsLoading(false);
      const { data } = response as IRestPaginate;
      setPageSize(data.pagination.total);
      setEstablishment(data.data);
    });
  }, [paginationModel]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      type: "string",
      minWidth: window.innerWidth / 8,
    },
    {
      field: "title",
      headerName: "Titulo",
      type: "string",
      minWidth: window.innerWidth / 8,
    },
    {
      field: "displayOrder",
      headerName: "Ordenação",
      type: "string",
      minWidth: window.innerWidth / 20,
    },
    {
      field: "line",
      headerName: "Linha",
      type: "string",
      minWidth: window.innerWidth / 20,
    },
    // {
    //   field: "filename",
    //   headerName: "Imagem",
    //   minWidth: 160,
    //   renderCell: (params) => {
    //     return (
    //       <button
    //         onClick={() => {
    //           Swal.fire({
    //             imageUrl: params.row.filename
    //               ? digitalApiUrl + params.row.filename
    //               : digitalAssetsUrl + "/assets/banner.jpg",
    //             imageWidth: 400,
    //             imageHeight: 200,
    //           });
    //         }}
    //         style={{
    //           backgroundColor: "#b96e6e",
    //           border: "none",
    //           cursor: "pointer",
    //           color: "#FFFFFF",
    //           fontWeight: "bold",
    //           height: "80%",
    //           width: "90%",
    //           borderRadius: "10px",
    //         }}
    //       >
    //         Imagem
    //       </button>
    //     );
    //   },
    // },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 160,
      renderCell: (params) => {
        return params.row.isActive ? (
          <button
            onClick={() => {
              Swal.fire({
                title: "Tem certeza que deseja desativar o Banner?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Sim",
                denyButtonText: "Não",
                confirmButtonColor: "#63adb7",
                denyButtonColor: "#b96ea5",
              }).then((result) => {
                if (result.isConfirmed) {
                  putBanner(params.row.id, { isActive: "inactive" }).then(
                    () => {
                      window.location.reload();
                    }
                  );
                }
              });
            }}
            style={{
              backgroundColor: "gray",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "80%",
              width: "90%",
              borderRadius: "10px",
            }}
          >
            Desativar
          </button>
        ) : (
          <button
            onClick={() => {
              Swal.fire({
                title: "Tem certeza que deseja Ativar o Banner?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Sim",
                denyButtonText: "Não",
                confirmButtonColor: "#63adb7",
                denyButtonColor: "#b96ea5",
              }).then((result) => {
                if (result.isConfirmed) {
                  putBanner(params.row.id, { isActive: "active" }).then(() => {
                    window.location.reload();
                  });
                }
              });
            }}
            style={{
              backgroundColor: "#63adb7",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "80%",
              width: "90%",
              borderRadius: "10px",
            }}
          >
            Ativar
          </button>
        );
      },
    },
    {
      field: "excluir",
      headerName: "Excluir",
      minWidth: 160,
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              Swal.fire({
                title: "Tem certeza que deseja excluir o Banner?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Sim",
                denyButtonText: "Não",
                confirmButtonColor: "#b96e8b",
                denyButtonColor: "#63adb7",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteBanner(params.row.id).then(() => {
                    window.location.reload();
                  });
                }
              });
            }}
            style={{
              backgroundColor: "#b96ea5",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "80%",
              width: "90%",
              borderRadius: "10px",
            }}
          >
            Excluir
          </button>
        );
      },
    },
    {
      field: "edit",
      headerName: "Visualizar",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <button
            onClick={() => Rota(params.row.id)}
            style={{
              backgroundColor: "#63adb7",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "80%",
              width: "100%",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImgSgv
              style={{ margin: "5px" }}
              width={18}
              src="/icons/menu/gerenciamento.svg"
            />
            Editar
          </button>
        );
      },
    },
  ];

  return (
    <>
      <div
        style={{
          alignContent: "space-between",
          alignItems: "space-between",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextH2
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "start",
            textAlign: "center",
            width: "100%",
          }}
        >
          <ImgSgv width={24} src="/icons/menu/visao.svg" />
          &nbsp;Banner
        </TextH2>
        <button
          onClick={() => Rota("New")}
          style={{
            backgroundColor: "#9c9b9f",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "128px",
            borderRadius: "10px",
            paddingRight: "10px",
            marginRight: "20px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px", marginLeft: "10px" }}
            width={18}
            src="/icons/menu/new.svg"
          />
          Cadastrar
        </button>
      </div>
      <br />
      <Box sx={{ height: "90%", width: "100%" }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={establishment!}
          columns={columns}
          rowCount={pageSize}
          loading={isLoading}
          pageSizeOptions={[20, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </>
  );
};

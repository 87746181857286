import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./Pages/Login";
// import { Home } from "./Pages/Home";
import { AdminRoutes } from "./Components/Auth/isAuthenticated";
import { ToastContainer } from "react-toastify";
import PageHeaderUser from "./Pages/Menu";
import { Overview } from "./Pages/Admin/Overview";
import { UserManagement } from "./Pages/Admin/UserManagement";
import { PlatformReport } from "./Pages/Admin/PlatformReport";
import { UserAdminRegister } from "./Pages/Admin/UserAdminRegister";
import { EstablishmentsRegistration } from "./Pages/Admin/EstablishmentsRegistration";
import { UserDetails } from "./Pages/Admin/DetailsUser";
import { EstablishmentDetails } from "./Pages/Admin/DetailsEstablishment";
import { Banner } from "./Pages/Admin/Banner";
import { BannerDetails } from "./Pages/Admin/Banner/BannerDetails";
import { Event } from "./Pages/Admin/Eventos";
import { EventDetails } from "./Pages/Admin/Eventos/EventDetails";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<AdminRoutes />}>
          <Route path="/" element={<Login />}></Route>
          <Route
            path="/home"
            element={<PageHeaderUser InternalComponent={<Overview />} />}
          />
          <Route
            path="/banner"
            element={<PageHeaderUser InternalComponent={<Banner />} />}
          />
          <Route
            path="/banner/:bannerId"
            element={<PageHeaderUser InternalComponent={<BannerDetails />} />}
          />
          <Route
            path="/eventos"
            element={<PageHeaderUser InternalComponent={<Event />} />}
          />
          <Route
            path="/eventos/:eventId"
            element={<PageHeaderUser InternalComponent={<EventDetails />} />}
          />
          <Route
            path="/UserManagement"
            element={<PageHeaderUser InternalComponent={<UserManagement />} />}
          />
          <Route
            path="/UserManagement/:userId"
            element={<PageHeaderUser InternalComponent={<UserDetails />} />}
          />
          <Route
            path="/UserAdminRegister"
            element={
              <PageHeaderUser InternalComponent={<UserAdminRegister />} />
            }
          />
          <Route
            path="/UserAdminRegister/:userId"
            element={<PageHeaderUser InternalComponent={<UserDetails />} />}
          />
          <Route
            path="/EstablishmentsRegistration"
            element={
              <PageHeaderUser
                InternalComponent={<EstablishmentsRegistration />}
              />
            }
          />
          <Route
            path="/EstablishmentsRegistration/:establishmentID"
            element={
              <PageHeaderUser InternalComponent={<EstablishmentDetails />} />
            }
          />
          <Route
            path="/PlatformReport"
            element={<PageHeaderUser InternalComponent={<PlatformReport />} />}
          />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default Router;

import { FormEvent, useEffect, useState } from "react";
import {
  DivFormLogin,
  // LoginA,
  LoginButton,
  LoginContent,
  // LoginContentA,
  LoginContentButton,
  LoginDiv,
  LoginFormControl,
  LoginFFH2,
  LoginB6H2,
  LoginIconButton,
  LoginInputAdornment,
  LoginInputLabel,
  LoginLogoContent,
  LoginOutlinedInput,
  LoginTituloDiv,
  ImgSgv,
  LoginContentA,
  LoginA,
  ForgotLoginDiv,
} from "./stylesLogin";
import { CircularProgress } from "@mui/material";
import {
  loginCheck,
  rememberPassword,
  rememberPasswordCode,
  rememberPasswordNew,
} from "../../Services/Auth";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);

  const [showForgotPassword, setShowForgotShowPassword] = useState(false);
  const [showForgotPasswordRequested, setShowForgotShowPasswordRequested] =
    useState(false);
  const [code, setCode] = useState<string>("");
  const [codeValid, setCodeValid] = useState<boolean>(false);
  const [newPasswordCode, setNewPasswordCode] = useState<boolean>(false);

  const login = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    loginCheck(username, password)
      .then((res) => {
        const localUser = localStorage.getItem("token");
        if (localUser) {
          return navigate("/home", { replace: true });
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const forgotPassword = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    rememberPassword(username)
      .then(() => {
        setShowForgotShowPasswordRequested(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const forgotPasswordNew = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    rememberPasswordNew(code, password, repeatNewPassword)
      .then(() => {
        setShowForgotShowPasswordRequested(false);
        setCodeValid(false);
        setNewPasswordCode(false);
        setShowRepeatNewPassword(false);
        setShowPassword(false);
        setShowForgotShowPassword(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClickShowPasswordRepeat = () =>
    setShowRepeatNewPassword((show) => !show);
  const handleMouseDownPasswordRepeat = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const LinearGradienteColor = [
    "#CB62A4",
    "#C464A4",
    "#B16CA7",
    "#9279AB",
    "#668CB0",
    "#2FA3B7",
    "#00B7BD",
  ];

  useEffect(() => {
    if (code.length >= 4) {
      setCodeValid(true);
      rememberPasswordCode(code)
        .then(() => {
          setCodeValid(true);
          setNewPasswordCode(true);
        })
        .catch((err) => {
          setCodeValid(false);
        });
    }
  }, [code]);

  return (
    <LoginContent primary={"#FAFAFA"}>
      <div
        style={{
          backgroundImage: `linear-gradient(160deg, ${LinearGradienteColor})`,
          color: "darkred",
          width: "100%",
          height: "100%",
        }}
      >
        <LoginLogoContent>
          <img width={"230px"} src={"/Pertalks.svg"} alt="Pertalks logo" />
        </LoginLogoContent>
      </div>
      {!showForgotPassword && (
        <LoginDiv>
          <LoginTituloDiv>
            <ImgSgv src="/icons/loginConfig.svg" />
            <LoginFFH2>&nbsp;&nbsp;ACESSAR&nbsp;</LoginFFH2>
            <LoginB6H2>PLATAFORMA</LoginB6H2>
          </LoginTituloDiv>
          <DivFormLogin onSubmit={login}>
            <br />
            <LoginFormControl variant="outlined">
              <LoginInputLabel htmlFor="username">Usuário:</LoginInputLabel>
              <LoginOutlinedInput
                autoComplete="username"
                defaultValue={username}
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                type="text"
                label="Usuário:"
              />
            </LoginFormControl>
            <br />
            <br />
            <LoginFormControl variant="outlined">
              <LoginInputLabel htmlFor="password">Senha:</LoginInputLabel>
              <LoginOutlinedInput
                autoComplete="current-password"
                defaultValue={password}
                inputProps={{ minLength: 8, maxLength: 30 }}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <LoginInputAdornment position="end">
                    <LoginIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? "Ocultar" : "Mostrar"}
                    </LoginIconButton>
                  </LoginInputAdornment>
                }
                label="Senha:"
              />
            </LoginFormControl>
            <LoginContentA>
              <LoginA
                onClick={() => setShowForgotShowPassword(!showForgotPassword)}
              >
                Esqueci minha senha
              </LoginA>
            </LoginContentA>
            <LoginContentButton>
              <LoginButton
                disabled={!(username !== "" && password !== "")}
                title="ACESSAR"
              >
                {isLoading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    <ImgSgv height={24} src="/icons/check.svg" />
                    &nbsp;&nbsp;Entrar
                  </div>
                )}
              </LoginButton>
            </LoginContentButton>
          </DivFormLogin>
        </LoginDiv>
      )}
      {showForgotPassword && !showForgotPasswordRequested && (
        <ForgotLoginDiv>
          <LoginTituloDiv>
            <ImgSgv src="/icons/loginConfig.svg" />
            <LoginFFH2>&nbsp;&nbsp;Recuperar&nbsp;</LoginFFH2>
            <LoginB6H2>Senha</LoginB6H2>
          </LoginTituloDiv>
          <DivFormLogin onSubmit={forgotPassword}>
            <br />
            <LoginFormControl variant="outlined">
              <LoginInputLabel htmlFor="username">Usuário:</LoginInputLabel>
              <LoginOutlinedInput
                autoComplete="username"
                defaultValue={username}
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                type="text"
                label="Usuário:"
              />
            </LoginFormControl>
            <br />
            <br />
            <LoginContentA>
              <LoginA
                onClick={() => setShowForgotShowPassword(!showForgotPassword)}
              >
                Voltar
              </LoginA>
            </LoginContentA>
            <LoginContentButton>
              <LoginButton
                disabled={!(username !== "")}
                title="Solicitar trocar de senha"
              >
                {isLoading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    <ImgSgv height={24} src="/icons/check.svg" />
                    &nbsp;&nbsp;Solicitar trocar de senha
                  </div>
                )}
              </LoginButton>
            </LoginContentButton>
          </DivFormLogin>
        </ForgotLoginDiv>
      )}
      {showForgotPassword && showForgotPasswordRequested && (
        <ForgotLoginDiv>
          <LoginTituloDiv>
            <ImgSgv src="/icons/loginConfig.svg" />
            <LoginFFH2>&nbsp;&nbsp;Validar&nbsp;</LoginFFH2>
            <LoginB6H2>Código</LoginB6H2>
          </LoginTituloDiv>
          <DivFormLogin onSubmit={forgotPasswordNew}>
            <br />
            <LoginFormControl variant="outlined">
              <LoginInputLabel htmlFor="username">CÓDIGO:</LoginInputLabel>
              <LoginOutlinedInput
                autoComplete="off"
                defaultValue={setCode}
                onChange={(e) => setCode(e.target.value)}
                id="code"
                type="text"
                label="CÓDIGO:"
                disabled={codeValid}
              />
            </LoginFormControl>
            <br />
            <br />
            {newPasswordCode && (
              <>
                <LoginFormControl variant="outlined">
                  <LoginInputLabel htmlFor="password">
                    Nova Senha:
                  </LoginInputLabel>
                  <LoginOutlinedInput
                    autoComplete="off"
                    defaultValue={password}
                    inputProps={{ minLength: 8, maxLength: 30 }}
                    onChange={(e) => setPassword(e.target.value)}
                    id="newPassword"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <LoginInputAdornment position="end">
                        <LoginIconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? "Ocultar" : "Mostrar"}
                        </LoginIconButton>
                      </LoginInputAdornment>
                    }
                    label="Nova Senha:"
                  />
                </LoginFormControl>
                <br />
                <br />
                <LoginFormControl variant="outlined">
                  <LoginInputLabel htmlFor="password">
                    Repita a Senha:
                  </LoginInputLabel>
                  <LoginOutlinedInput
                    autoComplete="off"
                    defaultValue={repeatNewPassword}
                    inputProps={{ minLength: 8, maxLength: 30 }}
                    onChange={(e) => setRepeatNewPassword(e.target.value)}
                    id="repeatNewPassword"
                    type={showRepeatNewPassword ? "text" : "password"}
                    endAdornment={
                      <LoginInputAdornment position="end">
                        <LoginIconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordRepeat}
                          onMouseDown={handleMouseDownPasswordRepeat}
                          edge="end"
                        >
                          {showRepeatNewPassword ? "Ocultar" : "Mostrar"}
                        </LoginIconButton>
                      </LoginInputAdornment>
                    }
                    label="Repita a Senha:"
                  />
                </LoginFormControl>
                <br />
                <br />
              </>
            )}
            <LoginContentA>
              <LoginA
                onClick={() =>
                  setShowForgotShowPasswordRequested(
                    !showForgotPasswordRequested
                  )
                }
              >
                Solicitar Novo Código
              </LoginA>
            </LoginContentA>
            <LoginContentButton>
              <LoginButton
                disabled={!(password === repeatNewPassword && password !== "")}
                title="Solicitar trocar de senha"
              >
                {isLoading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      color: "#fff",
                    }}
                  >
                    <ImgSgv height={24} src="/icons/check.svg" />
                    &nbsp;&nbsp;Solicitar trocar de senha
                  </div>
                )}
              </LoginButton>
            </LoginContentButton>
          </DivFormLogin>
        </ForgotLoginDiv>
      )}
      <div
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          textAlign: "center",
          color: "darkred",
          fontSize: "12px",
          fontWeight: "bold",
          paddingBottom: "60px",
        }}
      >
        <LoginA target="_blank" href="https://www.cm2tech.com.br/">
          Desenvolvido por: Maac-Tech
        </LoginA>
      </div>
    </LoginContent>
  );
};

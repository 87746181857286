import axios from "axios";

export const baseURL = process.env.REACT_APP_API_URL;
export const digitalApiUrl = process.env.REACT_APP_DIGITAL_API_URL;
export const digitalAssetsUrl = process.env.REACT_APP_DIGITAL_ASSETS_URL;

export const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, ptBR, GridColDef } from "@mui/x-data-grid";
import { TextH2 } from "../../../styles/global.styles";
import { IEstablishment, IRestPaginate } from "../../../Services/Interfaces";
import { ImgSgv } from "../../Login/stylesLogin";
import { getEstablishment } from "../../../Services/Establishment";
import { AxiosResponse } from "axios";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const EstablishmentsRegistration = () => {
  const navigate = useNavigate();

  const Rota = (path: string) => {
    return navigate(`${path}`, { replace: true });
  };
  const [establishment, setEstablishment] = useState<IEstablishment[]>([]);
  const [pageSize, setPageSize] = useState(20);
  const [establishmentName, setEstablishmentName] = useState<string>("");
  const [addressInGoogleFormat, setAddressInGoogleFormat] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });
  const [filterModel, setFilterModel] = useState({
    establishmentName: "",
    addressInGoogleFormat: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getEstablishment(
      paginationModel.page * paginationModel.pageSize,
      paginationModel.pageSize,
      filterModel.establishmentName,
      filterModel.addressInGoogleFormat
    ).then((response) => {
      setIsLoading(false);
      const { data } = response as IRestPaginate;
      setPageSize(data.pagination.total);
      setEstablishment(data.data);
    });
  }, [
    filterModel.addressInGoogleFormat,
    filterModel.establishmentName,
    paginationModel,
  ]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      type: "string",
      minWidth: window.innerWidth / 4.8,
    },
    {
      field: "addressInGoogleFormat",
      headerName: "Endereço",
      type: "string",
      minWidth: window.innerWidth / 2.6,
    },
    {
      field: "edit",
      headerName: "Visualizar",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <button
            onClick={() => Rota(params.row.id)}
            style={{
              backgroundColor: "#63adb7",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "80%",
              width: "100%",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImgSgv
              style={{ margin: "5px" }}
              width={18}
              src="/icons/menu/gerenciamento.svg"
            />
            Visualizar
          </button>
        );
      },
    },
  ];

  const handleFilterEnter = () => {
    setPaginationModel({ page: 0, pageSize: 20 });
    setFilterModel({ establishmentName, addressInGoogleFormat });
  };

  return (
    <>
      <div
        style={{
          alignContent: "space-between",
          alignItems: "space-between",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextH2>
          <ImgSgv width={22} src="/icons/menu/estabelecimento.svg" />
          &nbsp;Estabelecimentos
        </TextH2>
        <button
          onClick={() => {
            // export xlsx file
            getEstablishment(
              paginationModel.page * paginationModel.pageSize,
              paginationModel.pageSize,
              filterModel.establishmentName,
              filterModel.addressInGoogleFormat,
              true
            ).then((response) => {
              const dow = response as AxiosResponse<Blob>;
              if (dow.status === 200) {
                const downloadUrl = window.URL.createObjectURL(dow.data);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute(
                  "download",
                  `Establishment-${new Date().getTime()}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
              }
            });
          }}
          style={{
            backgroundColor: "#63adb7",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "120px",
            borderRadius: "10px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px" }}
            width={18}
            src="/icons/menu/export.svg"
          />
          Exportar
        </button>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          style={{ width: "100%", paddingRight: "20px" }}
          id="name"
          label="Nome"
          variant="outlined"
          value={establishmentName}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleFilterEnter();
          }}
          onChange={(e) => {
            setEstablishmentName(e.target.value);
          }}
        />
        <TextField
          style={{ width: "100%", paddingRight: "20px" }}
          id="addressInGoogleFormat"
          label="Endereço"
          variant="outlined"
          type="text"
          value={addressInGoogleFormat}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleFilterEnter();
          }}
          onChange={(e) => {
            setAddressInGoogleFormat(e.target.value);
          }}
        />
        <button
          onClick={() => {
            setEstablishmentName("");
            setAddressInGoogleFormat("");
            setFilterModel({
              establishmentName: "",
              addressInGoogleFormat: "",
            });
          }}
          style={{
            backgroundColor: "#b96ea5",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "260px",
            marginRight: "20px",
            borderRadius: "10px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px" }}
            width={18}
            src="/icons/menu/filter_clear.svg"
          />
          Limpar
        </button>
        <button
          onClick={() => {
            handleFilterEnter();
          }}
          style={{
            backgroundColor: "#000000",
            border: "none",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            height: "56px",
            width: "260px",
            borderRadius: "10px",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgSgv
            style={{ marginRight: "5px" }}
            width={18}
            src="/icons/menu/filter.svg"
          />
          Filtrar
        </button>
      </div>
      <br />
      <Box sx={{ height: "90%", width: "100%" }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={establishment!}
          columns={columns}
          rowCount={pageSize}
          loading={isLoading}
          pageSizeOptions={[20, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
    </>
  );
};

import { styled } from "@mui/material/styles";

type HealthPlaceProfileProps = {
  secondBackgroundColor: string;
  firstBackgroundColor: string;
};
type HealthPlaceProfileTextProps = {
  primary: string;
};

export const SidebarHeaderContainer = styled("div")<HealthPlaceProfileProps>`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${(props) => props.firstBackgroundColor} 0%,
    ${(props) => props.firstBackgroundColor} 80px,
    #fafafa 80px,
    #fafafa 100%
  );
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const Sidebar = styled("div")<HealthPlaceProfileProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  min-width: 300px;
  min-height: 100%;

  background: linear-gradient(
    to bottom,
    ${(props) => props.firstBackgroundColor} 0%,
    ${(props) => props.firstBackgroundColor} 80px,
    ${(props) => props.secondBackgroundColor} 80px,
    ${(props) => props.secondBackgroundColor} 100%
  );

  position: relative;
`;

export const MenuContainerSection = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: #313030;
  }
  padding-left: 10px;
`;

export const MenuContainer = styled("div")`
  padding: 38px 10px;
`;

export const MenuContainerHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0;
  justify-items: center;
`;

export const MenuLinearGradienteDow = styled("div")`
  background-image: linear-gradient(0deg, #333333 0%, #333333 100%);
  width: 100%;
  height: 4px;
`;

export const HeaderContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const LogoMenu = styled("img")`
  height: 40px;
  margin-top: 20px;
  padding-left: 20px;
  cursor: pointer;
`;

export const Header = styled("div")`
  align-self: end;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  padding: 22px 30px;
  box-shadow: 0px 0px 2px rgba(92, 89, 89, 0.06);
`;

export const SidebarMenu = styled("div")``;

export const CopyrightContainer = styled("div")`
  padding: 10px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
`;

export const ComponentContent = styled("div")`
  /* background-color: white; */
  border-radius: 6px;
  height: 100%;
  margin: 28px 20px;
  min-width: 900px;
  /* desativado scroll  */
  /* overflow: hidden; */
  padding: 8px;
`;

export const LinkA = styled("a")<HealthPlaceProfileTextProps>`
  text-decoration: none;
  color: ${(props) => props.primary};
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HideItemDesk = styled("div")`
  display: flex;
`;

export const AvatarContainer = styled("img")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  margin-right: 16px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

export const ImgSgv = styled("img")``;

export const LoginB6H3 = styled("h3")`
  color: #00b6bc;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const LoginFFH3 = styled("h3")`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const MenuTituloDiv = styled("div")`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`;

import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { iTokenDecoded } from "../../Services/Interfaces";

export const Logout = (cancelMessage?: boolean) => {
  localStorage.clear();
  if (!cancelMessage) toast.info("Sessão encerrada com sucesso!");
  setTimeout(() => {
    if (window.location.pathname !== "/login") {
      return (window.location.href = "/login");
    } else {
      return;
    }
  }, 6000);
};

export const checkChaves = () => {
  if (localStorage.getItem("token") === "undefined") {
    localStorage.clear();
  }
  const token =
    localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== "" &&
    localStorage.getItem("token") !== null
      ? true
      : false;

  const tokenValido = () => {
    if (token) {
      const decoded = jwtDecode(
        localStorage.getItem("token") as string
      ) as any;
      // verificar token expirado
      if (decoded.exp < Date.now() / 1000) {
        localStorage.clear();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return token && tokenValido();
};

export const checkPerfilAdmin = () => {
  const token =
    localStorage.getItem("token") !== undefined &&
    localStorage.getItem("token") !== "" &&
    localStorage.getItem("token") !== null
      ? true
      : false;

  if (token) {
    const decoded = jwtDecode(
      localStorage.getItem("token") as string
    ) as iTokenDecoded;

    if (decoded.roles.includes("ROLE_ADMIN")) {
      return true;
    } else if (decoded.roles.includes("ROLE_SUPER_ADMIN")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const AdminRoutes = () => {
  if (checkChaves() && checkPerfilAdmin()) {
    return <Outlet />;
  } else if (checkChaves()) {
    return <Navigate to="/home" />;
  } else {
    localStorage.clear();
    return <Navigate to="/login" />;
  }
};

import { ReactElement } from "react";
import { ImgSgv } from "../../../Login/stylesLogin";
import {
  ComponentContentBox,
  GradienteDowBox,
  SectionBox,
  SectionBoxTitle,
  SectionBoxTitleIcon,
} from "./styles";

interface BoxReportProps {
  title: string;
  ImgSgvIco: string;
  InternalComponent: ReactElement<any, any>;
  tipoExport: string;
  ExportCSV: (report: string) => void;
}

export const BoxReportDuplo: React.FC<BoxReportProps> = ({
  title,
  InternalComponent,
  ImgSgvIco,
  tipoExport,
  ExportCSV,
}) => {
  return (
    <SectionBox>
      <SectionBoxTitle>
        <SectionBoxTitleIcon>
          <ImgSgv style={{ marginRight: "10px" }} width={26} src={ImgSgvIco} />
          <h4
            style={{
              fontStyle: "bold",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "24px",
              color: "#333",
            }}
          >
            {title}
          </h4>
        </SectionBoxTitleIcon>
        <div>
          <button
            onClick={() => {
              ExportCSV(tipoExport);
            }}
            style={{
              backgroundColor: "#63adb7",
              border: "none",
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              height: "36px",
              width: "100px",
              borderRadius: "10px",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ImgSgv
              style={{ marginRight: "5px" }}
              width={18}
              src="/icons/menu/export.svg"
            />
            Exportar
          </button>
        </div>
      </SectionBoxTitle>
      <br />
      <GradienteDowBox />
      <ComponentContentBox>{InternalComponent}</ComponentContentBox>
    </SectionBox>
  );
};

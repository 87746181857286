import { toast } from "react-toastify";
import { Logout } from "../Components/Auth/isAuthenticated";
import { api } from "./Api";
import {
  IErroRest,
  IRest,
  IRestGetAppUserID,
  IRestGetUserMe,
  IRestPaginate,
} from "./Interfaces";

export const getAppUser = async (
  offset: number,
  limit: number,
  email: string = "",
  fullName: string = "",
  xlsx: boolean = false
) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(
            `/admin/app_user?offset=${offset}&limit=${limit}}${
              xlsx ? "&export=1" : ""
            }${email ? "&criteria[user.email]=" + email : ""}${
              fullName ? "&criteria[profile.fullName]=" + fullName : ""
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              responseType: xlsx ? "blob" : "json",
            }
          )
          .then((response: IRestPaginate) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getAppUserID = async (ID: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`admin/app_user/${ID}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IRestGetAppUserID) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putAppUserBlock = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(
            `/admin/app_user/block/${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestPaginate) => {
            window.location.reload();
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Bloqueando usuário...",
        success: {
          render(ToastContentProps) {
            const { data } = ToastContentProps.data as unknown as IRest;
            return data.message;
          },
        },
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const putAppUserUnblock = async (id: string) => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .put(
            `/admin/app_user/unblock/${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response: IRestPaginate) => {
            window.location.reload();
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        pending: "Desbloqueando usuário...",
        success: {
          render(ToastContentProps) {
            const { data } = ToastContentProps.data as unknown as IRest;
            return data.message;
          },
        },
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};

export const getAppUserMe = async () => {
  try {
    return toast.promise(
      new Promise((resolve, reject) => {
        api
          .get(`/pt_BR/me`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response: IRestGetUserMe) => {
            localStorage.setItem("fullName", response.data.data.profile.fullName);
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      }),
      {
        error: {
          render(ToastContentProps) {
            const data = ToastContentProps.data as unknown as IErroRest;
            return data.response.data.message;
          },
        },
      }
    );
  } catch (erro) {
    Logout();
    return false;
  }
};
